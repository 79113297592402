export const userImage = (user) => {
    return user?.picture ?? null
}

export const isEmailUser = (user) => {
    return user?.sub?.startsWith('email|') ?? false
}

export const userIdHeaderName = 'Auth-Access-Id'

export const authHeadersFor = (accessToken, userId) => {
    const authHeaders = { 'Authorization': `Bearer ${accessToken}` }
    authHeaders[userIdHeaderName] = userId // Provide this token for relaxed rate limiting for authenticated users
    return authHeaders
}

export const userIsRole = ({ user, role}) => {
    return user?.['https://www.chaostrack.com/roles']?.includes(role) ?? false
}

export const userOrg = (user) => {
    return user?.['https://www.chaostrack.com/orgId'] ?? ''
}

/**
 * From: https://stackoverflow.com/a/51655682/2333933
 * Returns a vue-router props function that maps the query params to component props according to the rules object passed in.
 * @param paramsToProps - Object defining param mapping rules: key = query name, value = prop name; Other params ignored.
 */
export const bindQueryParamsToProps = (paramsToProps) => {
    return (route) => {
        let paramsToPropsRules = paramsToProps ? Object.entries(paramsToProps) : []
        const paramProps = {}
        for (const [queryParam, propName] of paramsToPropsRules) {
            paramProps[propName] = route.query[queryParam]
        }
        return { ...route.params, ...paramProps }
    }
}