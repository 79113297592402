import * as Yup from 'yup'

export const nlpMetaTypes = Object.freeze({
    ALTERNATIVES: 'alternatives',
    ACRONYMS: 'acronyms'
})

export const sampleNLPMetaForType = (type) => {
    let sampleJSON = ''
    switch (type) {
        case nlpMetaTypes.ACRONYMS:
            sampleJSON =
`{
    "CISO": "Chief Information Security Officer",
    "M365": "Microsoft 365",
    "O365": "Office 365",
    "ISP": "Internet Service Provider",
    "IT": "Information Technology",
    "I.T.": "Information Technology",
    "OWA": "Outlook Web Access",
    "EAC": "Exchange Admin Center",
    "SPF": "Sender Policy Framework",
    "DKIM": "DomainKeys Identified Mail",
    "ISO": "Information Security Officer",
    "SDM": "Service Delivery Manager",
    "AD": "Active Directory",
    "MFA": "Multi-Factor Authentication",
    "CTO": "Chief Technology Officer",
    "CFO": "Chief Financial Officer"
}`
            break
        case nlpMetaTypes.ALTERNATIVES:
            sampleJSON =
`{
    "Check the user's Sent emails folder.": [
        "Check the user's sent mailbox.",
        "Check the sent mailbox",
        "Look in the sent mail folder."
    ],
    "Check the user's Junk/spam email folder.": [
        "Check the junk or spam folder.",
        "Check user's junk or spam email."
    ],
    "I don't know. I need help.": [
        "I'm not sure what to do next.",
        "Do you have any suggestions?",
        "I'm stuck.",
        "Can you give me a hint?"
    ]
}`
            break
    
        default:
            break
    }
    return sampleJSON
}

const nlpMetaTypeSchemas = {
  ACRONYMS: Yup.lazy((value) => {
    if (value && Object.keys(value).length > 0) {
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: Yup.string().required(
            `The value for acronym entry '${val}' cannot be empty.`
          ),
        }),
        {}
      )
      return Yup.object().shape(newEntries)
    }
    return Yup.mixed().required('Acronym table cannot be empty.')
  }),
  ALTERNATIVES: Yup.lazy((value) => {
    if (value && Object.keys(value).length > 0) {
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: Yup.array().of(Yup.string()),
        }),
        {}
      )
      return Yup.object().shape(newEntries)
    }
    return Yup.mixed().required('Alternatives table cannot be empty.')
  }),
}

export const isValidJSONForType = ({ value, type }) => {
    let isValid = false

    // Verify type
    if (Object.values(nlpMetaTypes).includes(type)) {
        // Verify content
        try {
            const content = JSON.parse(value)
            switch (type) {
                case nlpMetaTypes.ACRONYMS:
                    nlpMetaTypeSchemas.ACRONYMS.validateSync(content)
                    isValid = true
                    break
                case nlpMetaTypes.ALTERNATIVES:
                    nlpMetaTypeSchemas.ALTERNATIVES.validateSync(content)
                    isValid = true
                    break
                default:
                    break;
            }
        } catch (error) {
            console.log(`NLP content of type '${type}' failed validation: ${error}`)
            isValid = `${error}`
        }
    }

    return isValid
}