<template>
    <v-dialog 
      v-model="dialogModel"
      :retain-focus="false"
      content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center fill-height"
      persistent
      >
          <v-card :width="smAndUp ? '470px' : undefined">
            <v-card-title>
              <span class="text-h5">{{ 'New Game' }}</span>
            </v-card-title>
            <v-card-text>
                <v-form v-model="newGameValid" ref="newGameFormRef">
                  <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      ref="nameFieldRef"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Name" 
                      v-model="gameName"
                      :rules="gameNameRules"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="showOrgs">
                    <v-col cols="12">
                      <v-select
                        :items="orgChoices"
                        item-title="displayName"
                        item-value="id"
                        prepend-icon="mdi-account-group"
                        density="compact"
                        label="Organization"
                        variant="outlined"
                        hide-details="auto"
                        v-model="selectedOrgId"
                        :rules="orgRules"
                        required
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelGameCommit">
                Cancel
              </v-btn>
              <v-btn text @click="saveGameCommit" :disabled="!newGameValid">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import { createSession } from '../utils/data.js'
import { validAPINodeEntryMatchRegex, validAPINodeFieldMaxLength, validAPINodeErrorMessage, validator } from '../utils/uiUtils.js'
export default {
    name: 'NewGameDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        showOrgs: {
          type: Boolean,
          required: true
        },
        orgs: {
          type: Array,
          required: false,
          default: () => [{ id: null, displayName: 'None'}]
        }
    },
    emits: ['update:modelValue', 'new-game-saved'],
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        const dialogModel = ref(false)

        const gameName = ref('')

        const selectedOrgId = ref(null)

        const orgChoices = ref([]) // Org list selection items derived from server's org list

        // Auto focus handling

        const nameFieldRef = ref(null)

        //Theme Handling
        const theme = useTheme()
        const isDarkTheme = ref(theme.current.value === 'dark')
        watch(theme.current, () => {
          if (theme.current.value === 'dark') {
            isDarkTheme.value = true
          } else {
            isDarkTheme.value = false
          }
        })

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // V-Model 2-Way Binding
        watch(() => props.modelValue, (val) => {
          dialogModel.value = val
        }, { immediate: true })

        watch(dialogModel, (val) => {
          context.emit('update:modelValue', val) // Pass change up to parent component

          if (val) {
            // Autofocus the proper UI element after dialog becomes visible
            setTimeout(() => {
              nameFieldRef.value?.focus()
            }, 250)
          }
        })

        // Form Validation
        const newGameFormRef = ref(null)
        const newGameValid = ref(false)
        const gameNameRules = ref([
          v => {
            if (!v) {
              return 'Name is required'
            } else if (v.length > validAPINodeFieldMaxLength) {
              return `Value must be ${validAPINodeFieldMaxLength} characters or less`
            } else if (validator.matches(v, validAPINodeEntryMatchRegex) === false) {
              return validAPINodeErrorMessage
            }
            return true
          },
        ])
        const orgRules = ref([
          v => v !== null || 'An Organization is required'
        ])

        watch([gameName, selectedOrgId], () => {
          // Force re-validation when any field changes
          newGameFormRef.value?.validate()
        })

        // Org choices
        watch(() => props.orgs, (val) => {
          if (val) {
            // Use the org's 'displayName' in the selection UI but fall back to the id if necessary
            // Note: Archived orgs may not create new games
            orgChoices.value = val
              .filter((anOrg) => anOrg.archived != true)
              .map((anOrg) => ({ ...anOrg, displayName: anOrg.displayName || anOrg.id }))
          } else {
            orgChoices.value = []
          }
        }, { immediate: true })

        // Save Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const saveGameCommit = async () => {
            // Commit the save
            const saved = await createSession(await getAccessTokenSilently(), user.value?.sub, { 
                name: gameName.value,
                org: selectedOrgId.value ?? null
            })
            if (saved) {
              logger.debug(`Saved new game '${gameName.value}': ${JSON.stringify(saved)}`)

              // Signal data updated
              context.emit('new-game-saved', saved)
            
              // Close the dialog
              dialogModel.value = false

              // Reset the game name field
              gameName.value = ''
              selectedOrgId.value = null
            } else {
              logger.debug(`New game '${gameName.value}' not created. Check name isn't already in use.`)

              // Add validation rule for this name already in use
              const badName = gameName.value
              gameNameRules.value?.push(v => v !== badName || 'Name is already in use.')
              // Force re-validation
              newGameFormRef.value?.validate()
            }
        }

        const cancelGameCommit = () => {
          // Close the dialog
          dialogModel.value = false

          // Reset the game name field
          gameName.value = ''
          selectedOrgId.value = null
        }

        return {
            dialogModel,
            isDarkTheme,
            // Autofocus handling
            nameFieldRef,
            // Responsive Sizing
            smAndUp,
            // Form Fields
            gameName,
            orgChoices,
            selectedOrgId,
            // Form Validation
            newGameFormRef,
            newGameValid,
            gameNameRules,
            orgRules,
            saveGameCommit,
            cancelGameCommit
        }
    }
}
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}

// HACK: fix v-combobox text entry field width bug
:deep(.v-field__input > input) {
  width: 100%;
}

// HACK: limit v-combobox item list height
:deep(.v-menu .v-overlay__content > .v-list) {
	max-height: 450px !important;
}

</style>