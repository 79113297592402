<template>
    <v-dialog v-model="dialogModel" :retain-focus="false" content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center fill-height">
          <v-card :width="smAndUp ? '470px' : undefined">
            <v-card-title>
              <template v-if="editOrg">
                <span class="text-h5">{{ 'Edit Organization' }}</span>
              </template>
              <template v-else>
                <span class="text-h5">{{ 'New Organization' }}</span>
              </template>
            </v-card-title>
            <v-card-text>
                <v-form v-model="orgValid" ref="orgFormRef">
                  <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      ref="idFieldRef"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Identifier" 
                      v-model="orgId"
                      :rules="editOrg ? [] : orgIdRules"
                      :disabled="!!editOrg"
                      :required="!editOrg"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      ref="nameFieldRef"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Display Name" 
                      v-model="orgName"
                      :rules="orgNameRules"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Sponsor Name" 
                      v-model="sponsorName"
                      :rules="nameRules"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Sponsor Email" 
                      v-model="sponsorEmail"
                      :rules="emailRules"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                          density="compact"
                          v-model="isArchived"
                          label="Archived"
                        ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelOrgCommit">
                Cancel
              </v-btn>
              <v-btn text @click="saveOrgCommit" :disabled="orgValid === false">
                {{ editOrg ? 'Update' : 'Create' }}
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import { createOrg, updateOrg } from '../utils/data.js'
export default {
    name: 'OrgEditDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        editOrg: {
          type: Object,
          required: false,
          default: () => null
        }
    },
    emits: ['update:modelValue', 'org-saved'],
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        const dialogModel = ref(false)

        const orgId = ref('')
        const orgName = ref('')
        const sponsorName = ref('')
        const sponsorEmail = ref('')
        const isArchived = ref(false)

        // Auto focus handling

        const idFieldRef = ref(null)
        const nameFieldRef = ref(null)

        //Theme Handling
        const theme = useTheme()
        const isDarkTheme = ref(theme.current.value === 'dark')
        watch(theme.current, () => {
          if (theme.current.value === 'dark') {
            isDarkTheme.value = true
          } else {
            isDarkTheme.value = false
          }
        })

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // V-Model 2-Way Binding
        watch(() => props.modelValue, (val) => {
          dialogModel.value = val
        }, { immediate: true })

        watch(dialogModel, (val) => {
          context.emit('update:modelValue', val) // Pass change up to parent component

          if (val) {
            // Populate the fields with org to edit (if applicable)
            orgId.value = props.editOrg?.id ?? ''
            orgName.value = props.editOrg?.displayName ?? ''
            sponsorName.value = props.editOrg?.sponsorName ?? ''
            sponsorEmail.value = props.editOrg?.sponsorEmail ?? ''
            isArchived.value = props.editOrg?.archived ?? false

            // Autofocus the proper UI element after dialog becomes visible
            setTimeout(() => {
              if (props.editOrg) {
                nameFieldRef.value?.focus()
              } else {
                idFieldRef.value?.focus()
              }
            }, 250)
          }
        })

        // Form Validation
        const orgFormRef = ref(null)
        var validIdRegex = /^[a-z0-9-]+$/;
        const orgValid = ref(false)
        const orgIdRules = ref([
          v => v.search(validIdRegex) !== -1 || 'Only lowercase letters, numbers and hyphens allowed.',
          v => '0123456789-_'.includes(v.charAt(0)) === false || 'Must start with a lowercase letter.',
          v => (v.length > 0 && v.length <= 64) || 'Must have a length from 1 to 64 characters.',
        ])
        const orgNameRules = ref([
            v => {
                if (v) {
                    return v.length <= 256 || 'Display name must be 256 characters long or less'
                }
                return true
            },
        ])
        const emailRules = ref([
        v => {
            if (v) {
                if (/.+@.+/.test(v) === false) {
                    return 'E-mail must be valid'
                } else if (v !== v.trim()) {
                    return 'No leading or trailing spaces allowed'
                }   
            }
            return true
        },
        ])
        const nameRules = ref([
            v => {
                if (v) {
                    return v.length <= 256 || 'Sponsor name must be 256 characters long or less'
                }
                return true
            },
        ])

        // UI functions
        const resetFormFields = () => {
          // Reset the fields
          orgId.value = ''
          orgName.value = ''
          sponsorName.value = ''
          sponsorEmail.value = ''
          isArchived.value = false
        }

        // Save Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const saveOrgCommit = async () => {
            // Commit the save

            let saved
            // Update Org
            if (props.editOrg) {
              saved = await updateOrg(await getAccessTokenSilently(), user.value?.sub, { 
                id: orgId.value,
                displayName: orgName.value,
                sponsorName: sponsorName.value,
                sponsorEmail: sponsorEmail.value,
                archived: isArchived.value
              })
              logger.debug(`Updated org '${orgId.value}': ${JSON.stringify(saved)}`)
            } else {
              saved = await createOrg(await getAccessTokenSilently(), user.value?.sub, { 
                id: orgId.value,
                displayName: orgName.value,
                sponsorName: sponsorName.value,
                sponsorEmail: sponsorEmail.value,
                archived: isArchived.value
              })
              if (saved) {
                logger.debug(`Created org '${orgId.value}': ${JSON.stringify(saved)}`)
              } else {
                logger.debug(`Org '${orgId.value}' not created. Check that id is unique.`)
                // Add validation rule for this in-use id value
                const badOrgId = orgId.value
                orgIdRules.value?.push(v => v !== badOrgId || 'ID already in use.')
                // Force re-validation
                orgFormRef.value?.validate()
              }
            }

            if (saved) {
              // Signal data updated
              context.emit('org-saved', saved)
          
              // Close the dialog
              dialogModel.value = false

              resetFormFields()
            }
        }

        const cancelOrgCommit = () => {
          // Close the dialog
          dialogModel.value = false

          resetFormFields()
        }

        return {
            dialogModel,
            isDarkTheme,
            // Autofocus handling
            idFieldRef,
            nameFieldRef,
            // Responsive Sizing
            smAndUp,
            // Form Field Values
            orgId,
            orgName,
            sponsorName,
            sponsorEmail,
            isArchived,
            // Form Validation
            orgFormRef,
            orgValid,
            orgIdRules,
            orgNameRules,
            emailRules,
            nameRules,
            saveOrgCommit,
            cancelOrgCommit
        }
    }
}
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}
</style>