<template>
    <v-img
    class="mx-auto"
    height="300"
    :lazy-src="placeholderSrc"
    :max-width="width"
    :alt="alt"
    :src="imageSourceData"
  >
    <template v-slot:placeholder>
      <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular
          color="grey-lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
    </template>
  </v-img>
</template>

<script>
import { inject, ref, onMounted } from 'vue'
import { useDisplay } from 'vuetify'

export default {
    name: 'LazyImage',
    props: {
        src: {
            type: String,
            required: false,
            default: null
        },
        alt: {
            type: String,
            required: false,
            default: null
        },
        width: {
            type: Number,
            required: false,
            default: 600
        },
        placeholderSrc: {
            type: String,
            required: false,
            default: 'https://picsum.photos/id/11/100/60' // TODO: change this
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        srcLoader: {
          type: Function,
          required: false,
          default: () => { console.log(`No 'srcLoader' function provided for LazyImage component!`) }
        }
    },
    components: {
    },
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        // Image Data
        const imageSourceData = ref(null) // initially null, contains base64 content after fetch

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        onMounted(async () => {
            // Use src (if available)
            if (props.src) {
                imageSourceData.value = props.src
            } else {
                // Use srcLoader
                const imageSource = await props.srcLoader()
                imageSourceData.value = imageSource
            }
        })


        return {
            // Image props
            imageSourceData,
            // Responsive Sizing
            smAndUp
        }
    }
}
</script>

<style lang="scss" scoped>

</style>