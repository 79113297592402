import { userTokenResolvedString, tokenResolvedString } from "./uiUtils.js"
import {stringify} from 'csv-stringify/browser/esm/sync'

export const renderCSVStringWith = ({ header, data }) => {
  return stringify([header, ...data])
}

export const textFromHTMLString = (htmlString) => {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, 'text/html');
    // Extract the text content from the parsed document
    const textContent = doc.body.textContent || "";
    return textContent.trim().replace(/\n/g, " ").replace(/\r/g, " "); // Trim any leading or trailing whitespace and swap in spaces for line breaks
}

// Render All Incident Details Into a Text Transcript
export const renderIncidentsTranscript = ({
    gameDispatchTimestamp = null,
    incidents = [],
    variables = {},
    roleAssignments = {},
    userCache = {},
    queries = [],
    includeDebugDetails = true
}) => {

    const allRoles = {}
    Object.values(userCache).forEach(user => {
        allRoles[user.id] = {
            id: user.id,
            name: user.email,
            imageUrl: user.picture
        }
    })

    const transcriptEntries = []

    for (const incident of incidents) {
        // TODO: Render placeholders for any attachements (images)
        // const imageAttachments = incident.data.attachments
        //     ?.filter((attach) => ['png', 'jpg', 'jpeg', 'gif'].includes(attach.extension))
        //     ?.map((image, idx) => {
        //         return {
        //             src: `data:image/${image.extension}};base64,${image.data}`,
        //             key: idx,
        //             alt: `${image.name}.${image.extension}`
        //         }
        //     }) ?? []
        
        let messageId = incident.id
        let messageType = incident.type
        let messageAuthor = allRoles?.[incident.author]?.name ?? incident.author ?? ''
        if (messageAuthor === 'player' || !messageAuthor) {
            messageAuthor = 'ChaosTrack'
        }

        // Skip system or diag messages if not debug
        if (!includeDebugDetails) {
            if (messageType === 'system' || incident.diag === true) {
                continue // for loop
            }
        }

        // De-reference any <variable> tokens in the item's 'text' field
        // De-reference any <role> tokens in the item's 'text' field
        let messageContent = textFromHTMLString(incident.data?.text ? userTokenResolvedString(tokenResolvedString(incident.data.text, variables), roleAssignments) : '')
        let messageTimestamp = incident.data?.date ?? 0

        if (includeDebugDetails) {
            transcriptEntries.push([
                messageId,
                messageType,
                messageTimestamp,
                messageAuthor,
                messageContent
            ])
        } else {
            transcriptEntries.push([
                messageTimestamp,
                messageAuthor,
                messageContent
            ])
        }
    }

    const queryEntries = queries?.map(playerQuery => {
        const messageId = ''
        const messageType = 'query'
        const messageTimestamp = playerQuery.dateStamp
        const messageAuthor = allRoles?.[playerQuery.user]?.name ?? playerQuery.user ?? ''
        const messageContent = `Searched: '${playerQuery.phrase}'`
        if (includeDebugDetails) {
            return [
                messageId,
                messageType,
                messageTimestamp,
                messageAuthor,
                messageContent
            ]
        }
        return [
            messageTimestamp,
            messageAuthor,
            messageContent
        ]
    }) ?? []

    // Include queries only when NOT debug
    const allEntries = transcriptEntries.concat(includeDebugDetails ? [] : queryEntries)

    // Sort the rows in ascending time order
    allEntries.sort((a, b) => {
        if (includeDebugDetails) {
            return a[2] - b[2] // messageTimestamp
        }
        return a[0] - b[0] // messageTimestamp
    })

    return renderCSVStringWith({
        header: includeDebugDetails ? [
          'Id',
          'Type',
          'Timestamp',
          'Source',
          'Message',
        ] : [
            'Timestamp',
            'Source',
            'Message',
          ],
        data: allEntries.map((anEntry) => {
            // Final value mappings
            // Swap in the game dispatch time for timestamp '0' (if encountered)
            if (includeDebugDetails) {
                if (anEntry[2] === 0) {
                    anEntry[2] = gameDispatchTimestamp ?? 0
                }
                anEntry[2] = new Date(anEntry[2]).toISOString() // messageTimestamp
            } else {
                if (anEntry[0] === 0) {
                    anEntry[0] = gameDispatchTimestamp ?? 0
                }
                anEntry[0] = new Date(anEntry[0]).toISOString() // messageTimestamp
            }
            return anEntry
        }),
    })
}