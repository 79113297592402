<template>
    <v-card height="200">
        <div class="d-flex justify-start align-center">
            <v-card-title class="mr-0 pr-0">{{ listTitle }}</v-card-title>
            <v-btn class="ml-0 pl-0" icon="mdi-plus" variant="plain" @click="addNewItem"></v-btn>
        </div>
        <v-list v-if="nlpMetas.length > 0" elevation="2" density="compact" class="mx-2 my-2 rounded-lg"
            style="height: 125px; overflow-y: scroll;">
            <v-list-item v-for="dataItem in nlpMetas" :key="dataItem.name" prepend-icon="mdi-database">
                <v-list-item-title>{{ dataItem.name }}</v-list-item-title>
                <v-list-item-subtitle> {{ `Type: ${dataItem.type}` }}</v-list-item-subtitle>
                <v-list-item-subtitle> {{ `Updated: ${new Date(dataItem.updated).toISOString()}` }}</v-list-item-subtitle>
                <template v-slot:append>
                    <v-btn icon="mdi-database-edit-outline" variant="plain" @click="editSelectedItem(dataItem)"></v-btn>
                </template>
            </v-list-item>
        </v-list>
        <v-sheet v-else class="mx-2 my-2 rounded-lg">
            <div>{{ noDataMessage }}</div>
        </v-sheet>
        <NLPDataListEditDialog :isDarkTheme="isDarkTheme" v-model="showEditDialog" :dataItem="selectedDataItem" @dataItem-saved="dataItemSaved" />
    </v-card>
</template>

<script>
import { inject, ref } from 'vue'
import { useDisplay } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import NLPDataListEditDialog from './NLPDataListEditDialog.vue'
export default {
    name: 'NLPDataList',
    props: {
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        fetchNLPMetasFunc: {
            type: Function,
            required: false,
            default: async () => { console.log(`Warning: No fetchNLPMetasFunc assigned to NLPDataList!`)}
        },
        nlpMetas: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    emits: [],
    components: { NLPDataListEditDialog },
    setup(props) {
        // Logger
        const logger = inject('vuejs3-logger');
    
        const listTitle = ref('Installed NLP Metadata')
        const noDataMessage = ref('No NLP Metadata Items Have Been Installed.')
        
        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // Add/Edit Dialog
        const showEditDialog = ref(false)
        const selectedDataItem = ref(null)

        const dataItemSaved = async (savedDataItem) => {
            logger.debug(`NLP Data Saved: ${savedDataItem.name}`)
            await props.fetchNLPMetasFunc()
        }

        // Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const addNewItem = async () => {
            // Add a new data list item
            logger.debug(`Add data item clicked.`)
            selectedDataItem.value = null
            showEditDialog.value = true
        }
        const editSelectedItem = async (dataItem) => {
            // Open edit dialog for selected item
            logger.debug(`Edit item clicked for '${JSON.stringify(dataItem)}'.`)
            selectedDataItem.value = dataItem
            showEditDialog.value = true
        }

        return {
            // Responsive Sizing
            smAndUp,
            // Field Names
            listTitle,
            noDataMessage,
            // Dialogs
            showEditDialog,
            selectedDataItem,
            dataItemSaved,
            // Functions
            addNewItem,
            editSelectedItem
        };
    }
}
</script>

<style lang="scss" scoped>

</style>