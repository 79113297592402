<template>
    <v-dialog
      v-model="dialogModel"
      :retain-focus="false"
      content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center fill-height"
      persistent
      >
          <v-card :width="smAndUp ? '470px' : undefined">
            <v-card-title>
              <span class="text-h5">{{ 'Add New Sponsor Account' }}</span>
            </v-card-title>
            <v-card-text>
                <v-form v-model="newSponsorValid">
                  <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      ref="nameFieldRef"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Name" 
                      v-model="sponsorName"
                      :rules="sponsorNameRules"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Email" 
                      v-model="sponsorEmail"
                      :rules="sponsorEmailRules"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="orgSelectionList"
                        item-title="displayName"
                        item-value="id"
                        prepend-icon="mdi-account-group"
                        density="compact"
                        label="Organization"
                        variant="outlined"
                        hide-details="auto"
                        v-model="selectedOrgId"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelSponsorCommit">
                Cancel
              </v-btn>
              <v-btn text @click="saveSponsorCommit" :disabled="!newSponsorValid || !selectedOrgId">
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import { addSponsor } from '../utils/data.js'
export default {
    name: 'NewSponsorDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        orgs: {
          type: Array,
          required: true,
        }
    },
    emits: ['update:modelValue', 'new-sponsor-saved'],
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        const dialogModel = ref(false)

        const sponsorEmail = ref('')
        const sponsorName = ref('')

        const selectedOrgId = ref(null)

        // Auto focus handling

        const nameFieldRef = ref(null)

        //Theme Handling
        const theme = useTheme()
        const isDarkTheme = ref(theme.current.value === 'dark')
        watch(theme.current, () => {
          if (theme.current.value === 'dark') {
            isDarkTheme.value = true
          } else {
            isDarkTheme.value = false
          }
        })

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // V-Model 2-Way Binding
        watch(() => props.modelValue, (val) => {
          dialogModel.value = val
        }, { immediate: true })

        watch(dialogModel, (val) => {
          context.emit('update:modelValue', val) // Pass change up to parent component

          if (val) {
            // Autofocus the proper UI element after dialog becomes visible
            setTimeout(() => {
              nameFieldRef.value?.focus()
            }, 250)
          }
        })

        // Org selection list
        const orgSelectionList = ref([])

        watch(() => props.orgs, (newOrgList) => {
          if (newOrgList) {
            orgSelectionList.value = newOrgList
              .filter((anOrg) => anOrg.archived != true)
              .map(({ id, displayName }) => ({ id, displayName: (displayName || id) }))
          } else {
            orgSelectionList.value = []
          }
        }, { immediate: true })

        // Form Validation
        const newSponsorValid = ref(false)
        const sponsorNameRules = ref([
          v => !!v || 'Name is required',
        ])
        const sponsorEmailRules = ref([
          v => {
              if (v) {
                if (/.+@.+/.test(v) === false) {
                    return 'E-mail must be valid'
                } else if (v !== v.trim()) {
                    return 'No leading or trailing spaces allowed'
                }
              } else {
                return 'E-mail is required'
              }
              return true
          },
        ])

        // Save Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const saveSponsorCommit = async () => {
            if (!selectedOrgId.value) {
              logger.error('Must select an org!')
            }
            // Commit the save
            const saved = await addSponsor(await getAccessTokenSilently(), user.value?.sub, { 
                name: sponsorName.value,
                email: sponsorEmail.value,
                orgId: selectedOrgId.value ?? null
            })
            logger.debug(`Added new sponsor with email '${sponsorEmail.value}': ${JSON.stringify(saved)}`)

            // Signal data updated
            context.emit('new-sponsor-saved', saved)
          
            // Close the dialog
            dialogModel.value = false

            // Reset the fields
            sponsorName.value = ''
            sponsorEmail.value = ''
        }

        const cancelSponsorCommit = () => {
          // Close the dialog
          dialogModel.value = false

          // Reset the game name field
          sponsorName.value = ''
          sponsorEmail.value = ''
        }

        return {
            dialogModel,
            isDarkTheme,
            // Autofocus handling
            nameFieldRef,
            // Responsive Sizing
            smAndUp,
            // Form Fields
            sponsorEmail,
            sponsorName,
            selectedOrgId,
            // Form Selection Lists
            orgSelectionList,
            // Form Validation
            newSponsorValid,
            sponsorNameRules,
            sponsorEmailRules,
            saveSponsorCommit,
            cancelSponsorCommit
        }
    }
  }
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}

// HACK: fix v-combobox text entry field width bug
:deep(.v-field__input > input) {
  width: 100%;
}

// HACK: limit v-combobox item list height
:deep(.v-menu .v-overlay__content > .v-list) {
	max-height: 450px !important;
}

</style>