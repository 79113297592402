<template>
    <v-container class="overflow-y-auto bg-transparent"
        fluid grid-list-sm>
        <v-row dense cols="12">
            <v-col>
                <div class="d-flex flex-column align-center justify-center fill-height">
                    <v-card height="200" width="464" elevation="20">
                        <div class="d-flex flex-column align-center justify-center fill-height">
                            <div :class="['text-h4', 'pb-3']">{{ prompt }}</div>
                            <v-card-subtitle class="pa-0 ma-0">{{ message }}</v-card-subtitle>
                        </div>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { inject, onMounted } from 'vue'
import { useDisplay } from 'vuetify'
export default {
name: 'ScenarioNotice',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: null
        },
        message: {
            type: String,
            required: false,
            default: 'This scenario is currently being configured.'
        },
        prompt: {
            type: String,
            required: false,
            default: 'Coming Soon...'
        }
    },
components: {
},
setup(props) {
    // Logger
    const logger = inject('vuejs3-logger')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()

    // Scenario Picker Functions
    const pickScenario = async (test) => {
        await props.pickScenarioFunc(test)
    }

    // Scenario Picker Event Watchers
    // watch(props.testPalette, () => {
    //   logger.debug(`Test palette changed!`)
    // })

    onMounted(() => {
        logger.debug(`Scenario Picker mounted!`)
    })

    return {
        // Window Size Management
        smAndUp,
        mdAndUp,
        // Scenario Picker Functions
        pickScenario
    }
}
}
</script>

<style lang="scss" scoped>

</style>