<template>
    <v-dialog v-model="dialogModel" :retain-focus="false" content-class="mv-0 mh-0 pv-0 ph-0 d-flex flex-column justify-center align-center fill-height">
          <v-card :width="smAndUp ? '75%' : '100%'">
            <v-card-title>
              <template v-if="dataItem">
                <span class="text-h5">{{ editItemTitle }}</span>
              </template>
              <template v-else>
                <span class="text-h5">{{ newItemTitle }}</span>
              </template>
            </v-card-title>
            <v-card-text>
                <v-form v-model="dataValid" ref="dataFormRef">
                  <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                      ref="nameFieldRef"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Name" 
                      v-model="dataItemName"
                      :rules="dataNameRules"
                      :disabled="!!dataItem"
                      required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :class="isDarkTheme ? 'text-input-white' : ''" 
                        :items="allDataTypes"
                        item-title="title"
                        item-value="value"
                        density="compact"
                        label="Type"
                        variant="outlined"
                        hide-details="auto"
                        v-model="dataItemType"
                        :rules="dataTypeRules"
                        required
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                      style="display: none;"
                      :class="isDarkTheme ? 'text-input-white' : ''" 
                      variant="outlined" 
                      label="Data Content"
                      rows="1"
                      v-model="dataItemContent"
                      :rules="dataContentRules"
                      ></v-textarea>
                      <Codemirror
                        ref="contentFieldRef"
                        :style="{ 'font-size': '12px' }"
                        v-model:value="dataItemContent"
                        @keypress="codeModified"
                        :height="350"
                        :options="{ mode: 'application/json', theme: (isDarkTheme ? 'dracula' : 'default'), lineNumbers: true, readOnly: false, foldGutter: true, lint: true, gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'] }"
                      />
                      <v-textarea
                        :class="[ (isDarkTheme ? 'text-input-red' : 'text-input-dark-red'), 'mt-1']"
                        variant="solo"
                        density="compact"
                        rows="5"
                        disabled
                        no-resize
                        v-model="dataContentHint"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelDataCommit">
                Cancel
              </v-btn>
              <v-btn text @click="saveDataCommit" :disabled="dataValid === false">
                {{ dataItem ? 'Update' : 'Create' }}
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { useAuth0 } from '@auth0/auth0-vue'
import { insertNLPMeta, updateNLPMeta } from '../utils/data.js'
import { nlpMetaTypes, sampleNLPMetaForType, isValidJSONForType } from '../utils/nlpUtils.js'

// CodeMirror (JSON code editor)
import Codemirror from "codemirror-editor-vue3"
import { JSHINT } from "jshint"; window.JSHINT = JSHINT; // Hacky: Any alternatives?
import "codemirror/mode/javascript/javascript.js"

import 'codemirror/addon/lint/lint.js'
import 'codemirror/addon/lint/javascript-lint.js'
import 'codemirror/addon/lint/lint.css'

import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/hint/show-hint.css'

import "codemirror/addon/display/placeholder.js"
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/foldcode.js"
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/foldgutter.css"

import "codemirror/theme/dracula.css"
export default {
    name: 'NLPDataListEditDialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        dataItem: {
          type: Object,
          required: false,
          default: () => null
        }
    },
    emits: ['update:modelValue', 'dataItem-saved'],
    components: {
      Codemirror
    },
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        const dialogModel = ref(false)

        const newItemTitle = ref('New NLP Metadata')
        const editItemTitle = ref('Edit NLP Metadata')

        // Data Item Types
        const dataTypeAcronyms = { title: 'Acronyms Table', value: nlpMetaTypes.ACRONYMS }
        const dataTypeAlternatives = { title: 'Alternative Phrase Table', value: nlpMetaTypes.ALTERNATIVES }

        const allDataTypes = ref([
          dataTypeAcronyms,
          dataTypeAlternatives
        ])

        // Form values

        const dataItemName = ref('')
        const dataItemType = ref(dataTypeAcronyms.value)
        const dataItemContent = ref('')
        const dataContentHint = ref('placeholder error goes here.')

        // Track user has editted content
        const userHasEdited = ref(false)

        // Auto focus handling

        const nameFieldRef = ref(null)
        const contentFieldRef = ref(null)

        // Responsive Sizing Logic
        const { smAndUp } = useDisplay()

        // V-Model 2-Way Binding
        watch(() => props.modelValue, (val) => {
          dialogModel.value = val
        }, { immediate: true })

        watch(dialogModel, (val) => {
          context.emit('update:modelValue', val) // Pass change up to parent component

          if (val) {
            // Populate the fields with data item to edit (if applicable)
            dataItemName.value = props.dataItem?.name ?? ''
            dataItemType.value = props.dataItem?.type ?? ''
            dataItemContent.value = props.dataItem?.content ?? ''

            // Autofocus the proper UI element after dialog becomes visible
            setTimeout(() => {
              if (props.dataItem && contentFieldRef.value?.cm) {
                contentFieldRef.value.cm.focus()
              } else {
                nameFieldRef.value?.focus()
              }
            }, 250)
          }
        })

        // Form Validation
        const dataFormRef = ref(null)
        var validNameRegex = /^[a-z0-9-]+$/;
        const dataValid = ref(false)
        const dataNameRules = ref([
          v => (v.length > 0 && v.length <= 64) || 'Must have a length from 1 to 64 characters.',
          v => v.search(validNameRegex) !== -1 || 'Only lowercase letters, numbers and hyphens allowed.',
          v => '0123456789-_'.includes(v.charAt(0)) === false || 'Must start with a lowercase letter.',
        ])

        const dataTypeRules = ref([
          v => (v?.length > 0) || 'Must choose a type.',
        ])

        const dataContentRules = ref([
          v => {
            const validationResult = isValidJSONForType({ value: v, type: dataItemType.value })
            if (validationResult !== false && validationResult !== true) {
              dataContentHint.value = validationResult
            } else {
              dataContentHint.value = ''
            }
            return validationResult
          }
        ])

        watch([dataItemName, dataItemType, dataItemContent], () => {
          // Setup placeholder content when in 'create' mode and user hasn't yet modified the content
          if (props.dataItem === null && dataItemType.value && userHasEdited.value === false) {
            dataItemContent.value = sampleNLPMetaForType(dataItemType.value)
          }

          // Force re-validation when any fields change
          dataFormRef.value?.validate()
        }, { immediate: true })

        // UI functions
        const codeModified = () => {
          // Code directly modified by user
          console.log(`Code directly modified by user!`)
          userHasEdited.value = true
        }

        const resetFormFields = () => {
          // Reset the fields
          dataItemName.value = ''
          dataItemType.value = ''
          dataItemContent.value = ''
          dataContentHint.value = ''
          userHasEdited.value = false
        }

        // Save Functions
        const { getAccessTokenSilently, user } = useAuth0()
        const saveDataCommit = async () => {
            // Commit the save

            let saved
            // Update NLP Data
            if (props.dataItem) {
              saved = await updateNLPMeta(await getAccessTokenSilently(), user.value?.sub, { 
                name: dataItemName.value,
                type: dataItemType.value,
                content: dataItemContent.value
              })
              logger.debug(`Updated NLP metadata item '${dataItemName.value}': ${JSON.stringify(saved)}`)
            } else {
              saved = await insertNLPMeta(await getAccessTokenSilently(), user.value?.sub, { 
                name: dataItemName.value,
                type: dataItemType.value,
                content: dataItemContent.value
              })
              if (saved) {
                logger.debug(`Created NLP metadata item '${dataItemName.value}': ${JSON.stringify(saved)}`)
              } else {
                logger.debug(`NLP data item '${dataItemName.value}' not created. Check that name is unique.`)
                // Add validation rule for this in-use id value
                const badDataName = dataItemName.value
                dataNameRules.value?.push(v => v !== badDataName || 'Name already in use.')
                // Force re-validation
                dataFormRef.value?.validate()
              }
            }

            if (saved) {
              // Signal data updated
              context.emit('dataItem-saved', saved)
          
              // Close the dialog
              dialogModel.value = false

              resetFormFields()
            }
        }

        const cancelDataCommit = () => {
          // Close the dialog
          dialogModel.value = false

          resetFormFields()
        }

        return {
            dialogModel,
            // Autofocus handling
            nameFieldRef,
            contentFieldRef,
            // Responsive Sizing
            smAndUp,
            // UI Constants
            newItemTitle,
            editItemTitle,
            // Form Field Values
            dataItemName,
            dataItemType,
            dataItemContent,
            // Form Validation
            allDataTypes,
            dataFormRef,
            dataValid,
            dataNameRules,
            dataTypeRules,
            dataContentRules,
            dataContentHint,
            // Functions
            codeModified,
            saveDataCommit,
            cancelDataCommit
        }
    }
}
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}

.text-input-dark-red {
  :deep(.v-field__input) {
    color: rgb(179, 1, 1) !important;
  }
}
.text-input-red {
  :deep(.v-field__input) {
    color: rgb(255, 103, 103) !important;
  }
}
</style>

<style lang="scss">

// Codemirror overrides
.CodeMirror-lint-tooltip
{
  z-index: 9999;
}
</style>