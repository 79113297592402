<template>
    <v-container :class="[inlineComponent ? undefined : 'overflow-y-auto', 'bg-transparent', 'pa-0']"
        :style="{ 'height': inlineComponent ? undefined : (height - 100 + 'px'), 'min-height': height + 'px', 'max-height': inlineComponent ? undefined : (height - 100 + 'px'), 'width': '100%' }"
        fluid grid-list-sm>
        <v-row dense cols="12">
            <v-col>
                <v-sheet :class="[inlineComponent ? undefined : 'pa-12']">
                    <v-sheet :elevation="4" class="mx-auto">
                        <v-card>
                            <v-card-title>Game Configuration</v-card-title>
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Archived" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select 
                                            v-model="archivedSetting"
                                            :hint="archivedSetting.detail" 
                                            :items="archivedOptions"
                                            item-title="display"
                                            persistent-hint return-object single-line
                                            :disabled="!isUpdateContext"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Organization" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                v-model="orgSetting" disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Scenario" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select disabled v-model="scenarioSetting" :hint="scenarioSetting.detail" :items="scenarioOptions"
                                            item-title="scenario"
                                            persistent-hint return-object single-line></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Dispatch Date" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <Datepicker
                                                v-model="formDispatchDate"
                                                :disabled="testSession.dispatched"
                                                :dark="isDarkTheme"
                                                :is-24="false"
                                                :month-change-on-scroll="false"
                                                :placeholder="(testSession.dispatched ? 'Game Has Dispatched' : 'Choose A Start Date')" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Difficulty" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="difficultySetting" :hint="difficultySetting.detail" :items="difficultyOptions"
                                            item-title="mode"
                                            persistent-hint return-object single-line></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Difficulty Options" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <div class="d-flex align-center justify-start">
                                                <v-checkbox-btn v-model="nlpAutoDisableEnabled" class="pe-2"></v-checkbox-btn>
                                                <div :class="['text-caption', 'pr-2', 'mr-0', (nlpAutoDisableEnabled ? undefined : 'text-disabled')]">
                                                    Revert Difficulty to Easy after {{ nlpAutoDisableThreshold }} Empty Action Searches
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <v-slider
                                                    :disabled="!nlpAutoDisableEnabled"
                                                    style="width: 400px;"
                                                    v-model="nlpAutoDisableThreshold"
                                                    :max="10"
                                                    :min="1"
                                                    step="1"
                                                    tick-size="4">
                                                </v-slider>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="NLP Model" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="nlpModelSetting" :items="nlpModelOptions"
                                            item-title="name"
                                            return-object single-line></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="NLP Acronyms Dataset" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="nlpAcronymsSetting" :items="nlpAcronymsOptions"
                                            item-title="name"
                                            item-value="name"
                                            no-data-text="No Acronyms Datasets Available"
                                            single-line></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="NLP Alternatives Dataset" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="nlpAlternativesSetting" :items="nlpAlternativesOptions"
                                            item-title="name"
                                            item-value="name"
                                            no-data-text="No Alternatives Datasets Available"
                                            single-line></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="NLP Model Options" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <div class="d-flex align-center justify-start">
                                                <div class="text-caption pr-2 mr-0">
                                                    Match Threshold Score: {{ nlpMatchThresholdScore }}
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <v-slider
                                                    style="width: 400px;"
                                                    v-model="nlpMatchThresholdScore"
                                                    :max="1.5"
                                                    :min="0.0"
                                                    step="0.01"
                                                    tick-size="4">
                                                </v-slider>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <div class="text-caption pr-2 mr-0">
                                                    Maximum Match Count: {{ nlpMatchLimit }}
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <v-slider
                                                    style="width: 400px;"
                                                    v-model="nlpMatchLimit"
                                                    :max="10.0"
                                                    :min="1.0"
                                                    step="1.0"
                                                    tick-size="4">
                                                </v-slider>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <div class="text-caption pr-2 mr-0">
                                                    Minimum Word Count (Queries): {{ nlpQueryMinWordCount }}
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <v-slider
                                                    style="width: 400px;"
                                                    v-model="nlpQueryMinWordCount"
                                                    :max="5.0"
                                                    :min="1.0"
                                                    step="1.0"
                                                    tick-size="4">
                                                </v-slider>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <div class="text-caption pr-2 mr-0">
                                                    Model 'Temperature': {{ nlpModelTemp }}
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <v-slider
                                                    style="width: 400px;"
                                                    v-model="nlpModelTemp"
                                                    :max="2.0"
                                                    :min="0.0"
                                                    step="0.01"
                                                    tick-size="4">
                                                </v-slider>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <div class="text-caption pr-2 mr-0">
                                                    Pre-procesor Alternatives Count: {{ nlpAlternativesCount }}
                                                </div>
                                            </div>
                                            <div class="d-flex align-center justify-start">
                                                <v-slider
                                                    style="width: 400px;"
                                                    v-model="nlpAlternativesCount"
                                                    :max="20.0"
                                                    :min="1.0"
                                                    step="1.0"
                                                    tick-size="4">
                                                </v-slider>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-sheet>
                    <v-sheet :elevation="4" class="mx-auto pt-4">
                        <v-card>
                            <v-card-title>Role Assignment</v-card-title>
                            <v-form v-model="rolesValid" ref="rolesFormRef">
                                <v-container>
                                    <!-- Roles -->
                                    <v-row v-for="(email, role, i) in formRoleAssignments" :key="i">
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                :label="role" disabled></v-text-field>
                                        </v-col>
                                        <!-- <v-col cols="12" md="3">
                                        <v-text-field
                                            label="Custom Title"></v-text-field>
                                    </v-col> -->
                                        <v-col cols="12" md="3">
                                            <v-text-field
                                                :rules="emailRules.concat(emailRulesForRole(role))"
                                                v-model="formRoleAssignments[role]"
                                                label="E-mail"
                                                required>
                                                <template v-if="formRoleAssignments[role]" v-slot:append-inner>
                                                    <v-tooltip location="top">
                                                        <template v-slot:activator="{ props }">
                                                            <v-icon
                                                                :color="userStatusIconColorForUser(formRoleAssignments[role])"
                                                                v-bind="props"
                                                                >{{ userStatusIconForUser(formRoleAssignments[role]) }}</v-icon>
                                                        </template>
                                                        <span v-html="userStatusTooltipContentForUser(formRoleAssignments[role])"></span>
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field :rules="nameRules" v-model="formRoleAssigneeNames[role]" label="Name"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field :rules="titleRules" :placeholder="role" v-model="formRoleAssigneeTitles[role]" label="Title"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1" v-if="formRoleAssignments[role] && showInviteUser({ email: formRoleAssignments[role] })">
                                            <v-btn
                                                class="ml-0 pl-0"
                                                icon="mdi-send-outline"
                                                variant="plain"
                                                @click="() => reInviteUser({ email: formRoleAssignments[role] })"
                                            ></v-btn>
                                        </v-col>
                                    </v-row>
                                    <!-- Spectators -->
                                    <v-row v-for="(spect, i) in formSpectators" :key="`spect_${i}`">
                                        <v-col cols="12" md="3">
                                            <v-text-field variant="solo"
                                                label="Spectator" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field
                                                :rules="emailRules.concat(emailRulesForSpectator(i))"
                                                v-model="formSpectators[i].email"
                                                label="E-mail"
                                                required>
                                                <template v-if="formSpectators[i].email" v-slot:append-inner>
                                                    <v-tooltip location="top">
                                                        <template v-slot:activator="{ props }">
                                                            <v-icon
                                                                :color="userStatusIconColorForUser(formSpectators[i].email)"
                                                                v-bind="props"
                                                                >{{ userStatusIconForUser(formSpectators[i].email) }}</v-icon>
                                                        </template>
                                                        <span v-html="userStatusTooltipContentForUser(formSpectators[i].email)"></span>
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field :rules="nameRules" v-model="formSpectators[i].name" label="Name"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-btn
                                                class="ml-0 pl-0"
                                                icon="mdi-delete-outline"
                                                variant="plain"
                                                @click="() => removeSpectatorAtIndex(i)"
                                            ></v-btn>
                                        </v-col>
                                        <v-col cols="12" md="1" v-if="formSpectators[i].email && showInviteUser({ email: formSpectators[i].email })">
                                            <v-btn
                                                class="ml-0 pl-0"
                                                icon="mdi-send-outline"
                                                variant="plain"
                                                @click="() => reInviteUser({ email: formSpectators[i].email })"
                                            ></v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-btn
                                                @click="newSpectatorButtonClicked"
                                            >Add Spectator</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-snackbar
                                :theme="isDarkTheme ? 'light' : 'dark'"
                                v-model="inviteUserResponseNotifVisible"
                                :timeout="inviteUserResponseTimeout"
                                >
                                <span :class="isDarkTheme ? undefined : 'text-white'">
                                    {{ inviteUserResponseText }}
                                </span>
                            </v-snackbar>
                        </v-card>
                    </v-sheet>
                    <v-sheet :elevation="4" class="mx-auto pt-4" v-if="variables.length > 0">
                        <v-card>
                            <v-card-title>Setup Questions</v-card-title>
                            <v-form v-model="variablesValid" ref="variablesFormRef">
                                <v-container>
                                    <v-row v-for="(variable, i) in variables" :key="i">
                                        <v-col cols="12" md="8">
                                            <v-textarea
                                                variant="solo"
                                                :label="variable.setupQuestion"
                                                disabled></v-textarea>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <template v-if="variable.type === 'ENUM_WITH_STRING_OPTION'">
                                            <!-- Single Select w/ Text Option -->
                                                <v-combobox :rules="textEntryRules" :label="variable.name" :items="variable.enums" v-model="formSetup[variable.name]"></v-combobox>
                                            </template>
                                            <template v-else-if="variable.type === 'STRING'">
                                            <!-- Text Field -->
                                                <v-text-field :rules="textEntryRules" :label="variable.name" v-model="formSetup[variable.name]"></v-text-field>
                                            </template>
                                            <template v-else-if="variable.type === 'ENUM'">
                                            <!-- Single Select -->
                                                <v-select :label="variable.name" :items="variable.enums" v-model="formSetup[variable.name]"></v-select>
                                            </template>
                                            <template v-else-if="variable.type === 'MULTI_ENUM_WITH_STRING_OPTION'">
                                            <!-- Multi Select w/ Text Option -->
                                                <v-combobox
                                                    :rules="textEntryRules"
                                                    :label="variable.name"
                                                    :items="variable.enums"
                                                    v-model="formSetup[variable.name]"
                                                    multiple
                                                    chips
                                                ></v-combobox>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-sheet>
                    <v-sheet :elevation="4" class="mx-auto pt-4">
                        <v-card>
                            <v-card-actions>
                                <v-btn v-if="isUpdateContext" block :disabled="!rolesValid || (variables.length > 0 && !variablesValid)" @click="submitForm">Save</v-btn>
                                <v-btn v-else-if="isFutureDispatchDate" block :disabled="!rolesValid || (variables.length > 0 && !variablesValid)" @click="submitForm">Schedule</v-btn>
                                <v-btn v-else block :disabled="!rolesValid || (variables.length > 0 && !variablesValid)" @click="submitForm">Start</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-sheet>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { inject, onMounted, onBeforeUnmount, ref, reactive, watch, computed } from 'vue'
import { useDisplay } from 'vuetify'
import {
    isDatePast,
    validator,
    emailValidationOptions,
    validNameFieldMatchRegex,
    validNameFieldMaxLength,
    validNameErrorMessage,
    validTextEntryMatchRegex,
    validTextEntryMaxLength,
    validTextErrorMessage
} from '../utils/uiUtils'
import { nlpMetaTypes } from '../utils/nlpUtils.js'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
name: 'ScenarioSetup',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: null
        },
        testSession: {
            type: Object,
            required: false,
            default: () => {}
        },
        nlpModels: {
            type: Array,
            required: false,
            default: () => []
        },
        nlpMetas: {
            type: Array,
            required: false,
            default: () => []
        },
        variables: {
            type: Array,
            required: false,
            default: () => []
        },
        isUpdateContext: {
            type: Boolean,
            required: false,
            default: false
        },
        inlineComponent: {
            type: Boolean,
            required: false,
            default: false
        },
        assignSettingsFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for assigning settings!') }
        },
        getUserDataFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for fetching user data!') }
        },
        inviteUserFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for inviting user!') }
        }
    },
components: {
    Datepicker
},
setup(props) {
    // Logger
    const logger = inject('vuejs3-logger')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()

    // Scenario Setup Form
    const rolesFormRef = ref(null)
    const variablesFormRef = ref(null)
    const rolesValid = ref(false)
    const variablesValid = ref(false)
    const emailRules = ref([
        // v => !!v || 'E-mail is required',
        v => {
            if (v) {
                if (validator.isEmail(v, emailValidationOptions) === false) {
                    return 'E-mail is invalid'
                } else if (v !== v.trim()) {
                    return 'No leading or trailing spaces allowed'
                }   
            }
            return true
        },
    ])
    const nameRules = ref([
        v => {
            if (v) {
                if (v.length > validNameFieldMaxLength) {
                    return `Name must be ${validNameFieldMaxLength} characters long or less`
                }
                if (validator.matches(v, validNameFieldMatchRegex) === false) {
                    return validNameErrorMessage
                }
            }
            return true
        },
    ])
    const titleRules = ref([
        v => {
            if (v) {
                if (v.length > validNameFieldMaxLength) {
                    return `Title must be ${validNameFieldMaxLength} characters long or less`
                }
                if (validator.matches(v, validNameFieldMatchRegex) === false) {
                    return validNameErrorMessage
                }
            }
            return true
        },
    ])
    
    const emailRulesForRole = (role) => {
        return [
            v => {
                // Check if role has a name assigned but no email
                if (!v && formRoleAssigneeNames[role]) {
                    return 'An Email is required for this role.'
                }
                return true
            },
        ]
    }

    const emailRulesForSpectator = (index) => {
        return [
            v => {
                // Check if spectator has a name assigned but no email
                if (!v && formSpectators[index].name) {
                    return 'An Email is required for this role.'
                }
                return true
            },
        ]
    }

    const validateTextEntry = (v) => {
        if (v) {
            if (v.length > validTextEntryMaxLength) {
                return `Value must be ${validTextEntryMaxLength} characters long or less`
            }
            if (validator.matches(v, validTextEntryMatchRegex) === false) {
                return validTextErrorMessage
            }
        }
        return true
    }

    const textEntryRules = ref([
        v => {
            if (Array.isArray(v)) {
                for (const vMember of v) {
                    const memberValidity = validateTextEntry(vMember)
                    if (memberValidity !== true) {
                        return memberValidity
                    }
                }
                return true
            }
            return validateTextEntry(v)
        },
    ])

    const formNLPEnabled = ref(true)
    const formRoleAssignments = reactive({})
    const formRoleAssigneeNames = reactive({})
    const formRoleAssigneeTitles = reactive({})
    const formSpectators = reactive([])
    const formSetup = reactive({})
    const formDispatchDate = ref(null)
    const formArchived = ref(false)

    const formUserData = reactive({})

    const isFutureDispatchDate = computed(() => {
        if (!formDispatchDate.value) {
            return false
        }
        if (isDatePast(formDispatchDate.value)) {
            return false
        }

        return true
    })

    const newSpectatorButtonClicked = () => {
        formSpectators.push({ name: '', email: '' })
    }

    const removeSpectatorAtIndex = (index) => {
        formSpectators.splice(index, 1)
    }

    watch(formRoleAssigneeNames, () => {
        // Force re-validation of role assignment form when a name field changes
        rolesFormRef.value?.validate()
    })

    watch(formSpectators, () => {
        // Force re-validation of role assignment form when a name field changes
        rolesFormRef.value?.validate()
    })

    watch(formSetup, () => {
        // Force re-validation of setup questinos form when values change
        variablesFormRef.value?.validate()
    })

    // Scenario Selector
    const noScenarioOption = { scenario: 'None', detail: 'No Scenario Configured' }
    const scenarioOptions = [noScenarioOption]
    const scenarioSetting = ref(noScenarioOption)

    // Organization Field
    const orgSettingDefault = 'none'
    const orgSetting = ref(orgSettingDefault)

    // Archived Field
    const archivedOption = { display: 'Yes', detail: 'This game is archived.', value: true }
    const notArchivedOption = { display: 'No', detail: 'This game is active.', value: false }
    const archivedSetting = ref(notArchivedOption)
    const archivedOptions = [notArchivedOption, archivedOption]

    // Difficult Selector
    const normalDiff = { mode: 'Normal', detail: 'NLP Action Selection Enabled' }
    const easyDiff = { mode: 'Easy', detail: 'NLP Action Selection Disabled' }
    const difficultySetting = ref(normalDiff)
    const difficultyOptions = [normalDiff, easyDiff]
    const nlpAutoDisableEnabled = ref(false)
    const nlpAutoDisableThreshold = ref(3)

    // NLP Model Config
    const gpt4Model = { name: 'gpt-4', value: 'gpt-4' } // default model
    const nlpModelSetting = ref(gpt4Model)
    const nlpModelOptions = computed(() => {
        // Skip gpt-4 model when populating other valid values
        const otherNLPOptions = props.nlpModels?.filter(model => model.name !== gpt4Model.name) ?? []
        return [gpt4Model, ...otherNLPOptions]
    })
    const nlpModelWithName = (name) => {
        return nlpModelOptions.value?.filter(model => model.name === name)?.pop() ?? gpt4Model
    }
    // NLP Metadata Config
    const nlpAlternativesSettingDefault = null
    const nlpAlternativesSetting = ref(nlpAlternativesSettingDefault)
    const nlpAlternativesOptions = computed(() => {
        return props.nlpMetas?.filter((nlpMeta) => nlpMeta.type === nlpMetaTypes.ALTERNATIVES) ?? []
    })
    const nlpAcronymsSettingDefault = null
    const nlpAcronymsSetting = ref(nlpAcronymsSettingDefault)
    const nlpAcronymsOptions = computed(() => {
        return props.nlpMetas?.filter((nlpMeta) => nlpMeta.type === nlpMetaTypes.ACRONYMS) ?? []
    })

    const defaultNLPMatchThresholdScore = 0.45
    const nlpMatchThresholdScore = ref(defaultNLPMatchThresholdScore)
    const defaultNLPModelTemp = 0.5
    const defaultNLPAlternativesCount = 2
    const defaultNLPMatchLimit = 5
    const defaultNLPQueryMinWordCount = 2
    const nlpModelTemp = ref(defaultNLPModelTemp)
    const nlpAlternativesCount = ref(defaultNLPAlternativesCount)
    const nlpMatchLimit = ref(defaultNLPMatchLimit)
    const nlpQueryMinWordCount = ref(defaultNLPQueryMinWordCount)
    const sourceNLPContext = ref({
        nlpModelTemp: defaultNLPModelTemp,
        nlpAlternativesCount: defaultNLPAlternativesCount,
        nlpMatchLimit: defaultNLPMatchLimit,
        nlpQueryMinWordCount: defaultNLPQueryMinWordCount
    })

    // Scenario Setup Functions
    const variablesForSubmission = (formVarData) => {
        // Iterate over the variable props and inject values from form
        const varMap = {}
        props.variables?.forEach((varDef) => {
            let value = formVarData?.[varDef.name]
            if (value) {
                if (Array.isArray(value)) {
                    value = value.join(',')
                }
                varMap[varDef.name] = {
                    type: varDef.type ?? 'STRING',
                    value
                }
            }
        })
        return varMap
    }

    const submitForm = async () => {
        await props.assignSettingsFunc({
            archived: formArchived.value,
            roleAssignments: formRoleAssignments,
            roleAssigneeNames: formRoleAssigneeNames,
            roleAssigneeTitles: formRoleAssigneeTitles,
            spectators: formSpectators,
            scheduledTime: formDispatchDate.value ? `${formDispatchDate.value.getTime()}` : null,
            nlpEnabled: formNLPEnabled.value,
            nlpMatchThreshold: nlpMatchThresholdScore.value,
            nlpModel: nlpModelSetting.value?.value !== undefined ? nlpModelSetting.value.value : nlpModelSetting.value.name,
            nlpAutoDisableThreshold: (nlpAutoDisableEnabled.value ? nlpAutoDisableThreshold.value : 0),
            variables: variablesForSubmission(formSetup),
            dispatched: isFutureDispatchDate.value ? null : true,
            nlpContext: sourceNLPContext.value
            ? {
                ...sourceNLPContext.value,
                nlpModelTemp: nlpModelTemp.value,
                nlpAlternativesCount: nlpAlternativesCount.value,
                nlpAlternativesId: nlpAlternativesSetting.value,
                nlpAcronymsId: nlpAcronymsSetting.value,
                nlpMatchLimit: nlpMatchLimit.value,
                nlpQueryMinWordCount: nlpQueryMinWordCount.value
            } 
            : {
                nlpModelTemp: nlpModelTemp.value,
                nlpAlternativesCount: nlpAlternativesCount.value,
                nlpAlternativesId: nlpAlternativesSetting.value,
                nlpAcronymsId: nlpAcronymsSetting.value,
                nlpMatchLimit: nlpMatchLimit.value,
                nlpQueryMinWordCount: nlpQueryMinWordCount.value
            }
        })
    }

    // User Account Data Logic
    const userStatusRefresher = ref(null)
    const userRosterRefreshHandler = ref(null)
    const userRosterDirty = ref(false)

    const refreshUserStatus = async () => {
        if (userRosterDirty.value === false) {
            logger.debug(`Skipping user data refresh. Data clean.`)
            return
        }
        try {
            userRosterDirty.value = false
            const assigneeEmails = Object.values(formRoleAssignments)
            const spectatorEmails = formSpectators.map((spectator) => (spectator.email))
            const userData = await props.getUserDataFunc({
                emails: assigneeEmails.concat(spectatorEmails)
            })
            if (userData) {
                for (const userDataItem of userData) {
                    const thisEmail = userDataItem.email
                    if (thisEmail) {
                        const lastLogin = userDataItem.last_login
                        const lastLoginAttempt = userDataItem.last_attempted_login
                        const lastLoginTime = lastLogin ? new Date(lastLogin).getTime() : null
                        const lastAttemptedLoginTime = lastLoginAttempt ? new Date(lastLoginAttempt).getTime() : null
                        const loginsCount = userDataItem.logins_count ?? 0
                        const logs = userDataItem.logs
                        const errorLogs = logs?.filter((log) => {
                            const logHasError = log.details?.error ? true : false
                            return logHasError
                        }) ?? []
                        formUserData[thisEmail] = { lastLoginTime, lastAttemptedLoginTime, loginsCount, errorLogs, logs }
                    }
                }
            }
            logger.debug(`User status: ${JSON.stringify(userData)}`)
        } catch (error) {
            logger.error(`Error refreshing user data: ${error}`)
        }

    }

    const userStatuses = Object.freeze({
        VERIFIED: 'Verified',
        PENDING: 'Pending',
        ERROR: 'Error',
        UNKNOWN: 'Unknown'
    })

    // Verified: user exists and has logged in since the account was last updated (created)
    const userVerifiedIcon = 'mdi-check-decagram-outline'
    // Pending: user exists but has not logged in since the account was last updated (created)
    const userPendingIcon = 'mdi-account-clock-outline' 
    // Error: user doesn't exist or user exists but there was a failed login attempt
    const userErrorIcon = 'mdi-alert-circle-outline'
    // Unknown: user not yet created
    const userUnknownIcon = 'mdi-account-question-outline'

    const userStatusIconForUser = (email) => {
        let userIcon
        switch (userStatusForUser(email)) {
            case userStatuses.VERIFIED:
                userIcon = userVerifiedIcon
                break
            case userStatuses.ERROR:
                userIcon = userErrorIcon
                break
            case userStatuses.PENDING:
                userIcon = userPendingIcon
                break
            default:
                userIcon = userUnknownIcon
                break
        }
        return userIcon
    }

    const userStatusIconColorForUser = (email) => {
        let userIconColor
        switch (userStatusForUser(email)) {
            case userStatuses.VERIFIED:
                userIconColor = 'green'
                break
            case userStatuses.ERROR:
                userIconColor = 'red'
                break
            case userStatuses.PENDING:
                userIconColor = 'yellow'
                break
            default:
                userIconColor = 'white'
                break
        }
        return userIconColor
    }

    const userStatusForUser = (email) => {
        
        let userStatus = userStatuses.PENDING

        const thisUserData = formUserData[email]
        if (thisUserData) {
            const userHasLoggedIn = thisUserData.loginsCount > 0
            const userHasLoggedInSinceGameStarted = props.testSession?.dispatchTime ? thisUserData.lastLoginTime >= props.testSession.dispatchTime : false
            // const userHasLoginErrorSinceGameStarted = props.testSession?.dispatchTime ? thisUserData.lastAttemptedLoginTime >= props.testSession.dispatchTime : false
            let userHasErrorLogsSinceGameStarted = false
            let userHasErrorLogsSinceLastLogin = false
            thisUserData.errorLogs?.forEach((log) => {
                const errorLogDateStamp = new Date(log.date).getTime()
                const errorIsSinceGameDispatch = props.testSession?.dispatchTime ? errorLogDateStamp >= props.testSession.dispatchTime : false
                const errorIsSinceLastLogin = thisUserData.lastLoginTime ? errorLogDateStamp >= thisUserData.lastLoginTime : false
                if (errorIsSinceLastLogin) userHasErrorLogsSinceLastLogin = true
                if (errorIsSinceGameDispatch) userHasErrorLogsSinceGameStarted = true
            })

            switch (true) {
                case (userHasErrorLogsSinceLastLogin || (userHasErrorLogsSinceGameStarted && !userHasLoggedInSinceGameStarted )):
                    userStatus = userStatuses.ERROR
                    break
                case (userHasLoggedIn && userHasLoggedInSinceGameStarted):
                    userStatus = userStatuses.VERIFIED
                    break
                default:
                    break;
            }
        } else {
            userStatus = userStatuses.UNKNOWN
        }

        return userStatus
    }

    const userStatusTooltipContentForUser = (email) => {
        const thisUserData = formUserData[email]
        if (thisUserData) {
            const logDescriptions = thisUserData.logs?.slice(0,10).map((log) => {
                const logErrorMessage = log.details?.error?.message
                let logType = log.type
                if (logType === 's') {
                    logType = 'Successful Login'
                } else if (logType === 'slo') {
                    logType = 'Successful Logout'
                }
                const logLocationCity = log.location_info?.city_name ?? "Unknown City"
                const logLocationCountry = log.location_info?.country_name ?? "Unknown Country"
                const logDate = log.date ?? 'Unknown Date'
                if (logErrorMessage) {
                    return `${logDate}: Error, ${logErrorMessage} (${logLocationCity}, ${logLocationCountry})`
                }
                return `${logDate}: ${logType} (${logLocationCity}, ${logLocationCountry})`
            }) ?? []
            return `
            <p>User Status: ${userStatusForUser(email)}</p>
            <p>Logins: ${thisUserData.loginsCount}</p>
            <p>Last Login: ${thisUserData.lastLoginTime ? new Date(thisUserData.lastLoginTime).toISOString() : 'Never'}</p>
            <p>Last Login Failure: ${thisUserData.lastAttemptedLoginTime ? new Date(thisUserData.lastAttemptedLoginTime) : 'Never'}</p>
            <p>Recent Logs:</p>
            ${logDescriptions.map((logMsg) => (`<p>${logMsg}</p>`)).join('\n')}
            `
        }
        return `
        <p>Status: ${userStatuses.UNKNOWN}</p>
        <p>No User Data Available</p>
        `
    }

    onMounted(async () => {
        userStatusRefresher.value = setInterval(refreshUserStatus, 7000)
        userRosterRefreshHandler.value = setInterval(() => {
            // Force eriodic user data refresh
            userRosterDirty.value = true
        }, 30000)
    })

    onBeforeUnmount(() => {
      clearInterval(userStatusRefresher.value)
      clearInterval(userRosterRefreshHandler.value)
    })

    // User Invite API
    const showInviteUser = ({ email }) => {
        // email must appear in game role assignments or spectator list
        // (i.e. must save user assignments before re-inviting
        let userIsAssignee = false
        let userIsSpectator = false
        if (props.testSession?.roleAssignments) {
            for (const roleEmail of Object.values(props.testSession.roleAssignments)) {
                if (roleEmail === email) {
                    userIsAssignee = true
                }
            }
        }
        if (props.testSession?.spectators) {
            for (const spect of props.testSession.spectators) {
                if (spect.email === email) {
                    userIsSpectator = true
                }
            }
        }
        return userIsAssignee || userIsSpectator
    }

    const inviteUserResponseNotifVisible = ref(false)
    const inviteUserResponseText = ref('')
    const inviteUserResponseTimeout = ref(4000)

    const reInviteUser = async ({ email }) => {
        inviteUserResponseNotifVisible.value = false
        inviteUserResponseText.value = ''
        const response = await props.inviteUserFunc({
            email,
            session: props.testSession?.name
        })
        if (response === false) {
            inviteUserResponseText.value = `The invitation was not sent. Check game configuration.`
        } else {
            inviteUserResponseText.value = `The invitation was sent.`
        }
        inviteUserResponseNotifVisible.value = true
    }

    // Scenario Setup Event Watchers
    watch(rolesValid, (val) => {
      logger.debug(`Roles input form validity changed: ${val}`)
    })

    watch([props.testSession, props.variables], ([seshVal, varsVal]) => {
        sourceNLPContext.value = seshVal.nlpContext
        if (seshVal.archived) {
            archivedSetting.value = archivedOption
        } else {
            archivedSetting.value = notArchivedOption
        }
        if (seshVal.test) {
            scenarioSetting.value = { scenario: seshVal.test, detail: `${seshVal.test} Selected` }
        } else {
            scenarioSetting.value = noScenarioOption
        }
        if (seshVal.org) {
            orgSetting.value = seshVal.org
        } else {
            orgSetting.value = orgSettingDefault
        }
        if (seshVal.nlpEnabled) {
            difficultySetting.value = normalDiff
        } else {
            difficultySetting.value = easyDiff
        }
        if (!isNaN(seshVal.nlpMatchThreshold)) {
            nlpMatchThresholdScore.value = seshVal.nlpMatchThreshold
        } else {
            nlpMatchThresholdScore.value = defaultNLPMatchThresholdScore
        }
        if (!isNaN(seshVal.nlpContext?.nlpModelTemp)) {
            nlpModelTemp.value = seshVal.nlpContext.nlpModelTemp
        } else {
            nlpModelTemp.value = defaultNLPModelTemp
        }
        if (!isNaN(seshVal.nlpContext?.nlpAlternativesCount)) {
            nlpAlternativesCount.value = seshVal.nlpContext.nlpAlternativesCount
        } else {
            nlpAlternativesCount.value = defaultNLPAlternativesCount
        }
        if (!isNaN(seshVal.nlpContext?.nlpMatchLimit)) {
            nlpMatchLimit.value = seshVal.nlpContext.nlpMatchLimit
        } else {
            nlpMatchLimit.value = defaultNLPMatchLimit
        }
        if (!isNaN(seshVal.nlpContext?.nlpQueryMinWordCount)) {
            nlpQueryMinWordCount.value = seshVal.nlpContext.nlpQueryMinWordCount
        } else {
            nlpQueryMinWordCount.value = defaultNLPQueryMinWordCount
        }
        if (seshVal.nlpContext?.nlpAlternativesId) {
            nlpAlternativesSetting.value = seshVal.nlpContext.nlpAlternativesId
        } else {
            nlpAlternativesSetting.value = nlpAlternativesSettingDefault
        }
        if (seshVal.nlpContext?.nlpAcronymsId) {
            nlpAcronymsSetting.value = seshVal.nlpContext.nlpAcronymsId
        } else {
            nlpAcronymsSetting.value = nlpAlternativesSettingDefault
        }
        if (seshVal.nlpModel) {
            nlpModelSetting.value = nlpModelWithName(seshVal.nlpModel)
        } else {
            nlpModelSetting.value = gpt4Model
        }
        if (seshVal.nlpAutoDisableThreshold === 0 || seshVal.nlpAutoDisableThreshold === undefined) {
            nlpAutoDisableEnabled.value = false
            nlpAutoDisableThreshold.value = 3
        } else {
            nlpAutoDisableEnabled.value = true
            nlpAutoDisableThreshold.value = seshVal.nlpAutoDisableThreshold
        }
        if (seshVal.scheduledTime) {
            formDispatchDate.value = new Date(seshVal.scheduledTime)
        } else {
            formDispatchDate.value = null
        }
        if (varsVal) {
            //Load any variable defaults
            for (const { default: varDefault, name: varName, type } of varsVal) {
                if (varDefault) {
                    const isArray = type === 'MULTI_ENUM_WITH_STRING_OPTION'
                    formSetup[varName] = isArray ? varDefault.split(',') : varDefault
                }
            }
        }
        if (seshVal.variables && Object.keys(seshVal.variables).length > 0) {
            // Load any stored variables from the session
            for(const [varName, varObj] of Object.entries(seshVal.variables)) {
                const varVal = varObj.value
                if (varVal) {
                    const isArray = varObj.type === 'MULTI_ENUM_WITH_STRING_OPTION'
                    formSetup[varName] = isArray ? varVal.split(',') : varVal
                }
            }
        }
    }, { immediate: true })

    watch(difficultySetting, (val) => {
        if (val.mode === normalDiff.mode) {
            formNLPEnabled.value = true
        } else {
            formNLPEnabled.value = false
        }
    }, { immediate: true })

    watch(archivedSetting, (val) => {
        formArchived.value = val.value
    }, { immediate: true })

    watch(props.testSession.roleAssignments, (val) => {
      logger.debug(`Session Roles have changed: ${JSON.stringify(val)}`)
      Object.keys(formRoleAssignments).forEach(key => delete formRoleAssignments[key])
      Object.assign(formRoleAssignments, val)
    }, { immediate: true })

    watch([formSpectators, formRoleAssignments], () => {
        userRosterDirty.value = true
    }, { immediate: true })

    watch(props.testSession.roleAssigneeNames, (val) => {
      logger.debug(`Session Role names have changed: ${JSON.stringify(val)}`)
      Object.keys(formRoleAssigneeNames).forEach(key => delete formRoleAssigneeNames[key])
      Object.assign(formRoleAssigneeNames, val)
    }, { immediate: true })

    watch(props.testSession.roleAssigneeTitles, (val) => {
      logger.debug(`Session Role titles have changed: ${JSON.stringify(val)}`)
      Object.keys(formRoleAssigneeTitles).forEach(key => delete formRoleAssigneeTitles[key])
      Object.assign(formRoleAssigneeTitles, val)
    }, { immediate: true })

    watch(props.testSession.spectators, (val) => {
      logger.debug(`Session spectators have changed: ${JSON.stringify(val)}`)
      formSpectators.splice(0)
      formSpectators.push(...val)
    }, { immediate: true })

    watch([nlpModelOptions, props.testSession], () => {
        if (nlpModelOptions.value && props.testSession?.nlpModel) {
            nlpModelSetting.value = nlpModelWithName(props.testSession.nlpModel)
        }
    }, { immediate: true })

    onMounted(() => {
        logger.debug(`Scenario Setup mounted!`)
    })

    return {
        // Window Size Management
        smAndUp,
        mdAndUp,
        // Scenario Setup Functions
        submitForm,
        // Scenario Setup Form
        rolesFormRef,
        rolesValid,
        variablesFormRef,
        variablesValid,
        emailRules,
        emailRulesForRole,
        emailRulesForSpectator,
        newSpectatorButtonClicked,
        removeSpectatorAtIndex,
        nameRules,
        titleRules,
        textEntryRules,
        formRoleAssignments,
        formRoleAssigneeNames,
        formRoleAssigneeTitles,
        formSpectators,
        formSetup,
        formDispatchDate,
        isFutureDispatchDate,
        difficultySetting,
        difficultyOptions,
        nlpAutoDisableEnabled,
        nlpAutoDisableThreshold,
        // Archived Setting
        archivedSetting,
        archivedOptions,
        // NLP Setup
        nlpModelSetting,
        nlpModelOptions,
        nlpMatchThresholdScore,
        nlpModelTemp,
        nlpAlternativesCount,
        nlpAcronymsOptions,
        nlpAcronymsSetting,
        nlpAlternativesOptions,
        nlpAlternativesSetting,
        nlpMatchLimit,
        nlpQueryMinWordCount,
        // Scenario Setup
        scenarioOptions,
        scenarioSetting,
        // Organization
        orgSetting,
        // User Data/Status APIs
        formUserData,
        userStatusIconForUser,
        userStatusTooltipContentForUser,
        userStatusIconColorForUser,
        // User Invite APIs
        inviteUserResponseNotifVisible,
        inviteUserResponseText,
        inviteUserResponseTimeout,
        showInviteUser,
        reInviteUser
    }
}
}
</script>

<style lang="scss" scoped>

// HACK: Allow text wrap in <v-textarea>
:deep(.v-textarea > .v-input__control > .v-field > .v-field__field > .v-label) {
    white-space: unset !important;
}

// HACK: fix but in v-combobox where selection text doesn't fill width of control
:deep(.v-field__input > input) {
  width: 100%;
}

</style>