const nodeForState = (state) => {
    return { name: state.name, icon: '&#xf04c' } /* Quiz */
}

const edgeForStateTransition = (state, trans) => {
    return { source: state.name, target: trans.target, name: edgeNameForStateTransition(state, trans) }
}

const edgeNameForStateTransition = (state, trans) => {
    return `${state.name}${trans.target}`
}

export const nodesForStates = (states) => {
    const nodes = {}
    states?.forEach(state => {
        nodes[state.name] = nodeForState(state)
    })
    return nodes
}

export const edgesForStateTransitions = (states) => {
    const edges = {}
    states?.forEach(state => {
        state.eventTransitions?.forEach(trans => {
            const thisEdge = edgeForStateTransition(state, trans)
            edges[thisEdge.name] = thisEdge
        })
    })
    return edges
}