import { createApp } from 'vue'
import Admin from './Admin.vue'
import { createRouter, createWebHistory } from 'vue-router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import VueLogger from 'vuejs3-logger'
import { createAuth0, authGuard } from '@auth0/auth0-vue'
import VNetworkGraph from 'v-network-graph'
import 'v-network-graph/lib/style.css'
import emitter from './components/vue3-beautiful-chat/emitter'
// import { bindQueryParamsToProps } from './utils/authUtils.js'

const isProd = process.env.VUE_APP_ORIGIN?.startsWith('https://')

const loggerOpts = {
  isEnabled: true,
  logLevel : isProd ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}

loadFonts()

const app = createApp(Admin)

// vue3-beautiful-chat
app.config.globalProperties.$event = emitter

// Global error logger
app.config.errorHandler = (err, instance, info) => {
  console.log(`Uncaught error: ${err}`)
}

app.use(createRouter({
    routes: [
      {
        path: '/',
        name: 'Splash',
        component: Admin
      },
      {
        path: '/error',
        name: 'Error',
        component: Admin
      },
      {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        beforeEnter: authGuard
      },
      {
        path: '/t/:navSession',
        name: 'Scenario',
        component: Admin,
        props: true,
        beforeEnter: authGuard
      }
    ],
    history: createWebHistory()
  }))
  .use(vuetify)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(VueLogger, loggerOpts)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN ?? '',
      clientId: process.env.VUE_APP_AUTH0_CLIENTID ?? '',
      authorizationParams: {
        redirect_uri: `${window.location.origin}/admin`,
        audience: 'hasura'
      }
    })
  )
  .use(VNetworkGraph)
  .mount('#app')
