<template>
  <v-app :theme="currentTheme">
    <!-- <v-navigation-drawer app>
    </v-navigation-drawer> -->

    <!-- Sizes your content based upon application components -->
    <v-main>
      <div class="d-flex flex-column align-center justify-center fill-height">
        <v-card height="180" width="350" elevation="20">
          <div class="d-flex flex-column align-center justify-center fill-height">
            <v-img
              :width="210"
              :max-height="40"
              :src="require('@/assets/logos/logo_placeholder.png')"
            ></v-img>
            <!-- <div class="pb-1">{{ componentTitle }}</div> -->
            <v-btn class="pb-3" size="x-small" variant="plain" disabled>
                v{{ projectVersion }}
            </v-btn>
            <!-- User Logged In -->
            <v-menu open-on-hover v-if="isAuthenticated">
              <template v-slot:activator="{ props }">
                <v-btn class="ml-3 mr-3" :variant="user.picture ? 'plain' : 'outlined'" icon size="x-small" color="grey"
                  v-bind="props">
                  <v-img v-if="user.picture" contain width="30" :src="user.picture" />
                  <v-icon v-else>
                    mdi-account-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item disabled>
                  <template v-slot:prepend>
                    <v-icon icon="mdi-account"></v-icon>
                  </template>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logInOrOut">
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- No User Logged In -->
            <v-btn class="ml-3 mr-3" v-if="!isAuthenticated" variant="outlined" size="x-small" prepend-icon="mdi-account-outline"
              color="grey" @click="logInOrOut">
              Log In
            </v-btn>
            <!-- <v-switch class="pt-2" style="flex: unset;" v-model="darkThemeSwitch" hide-details :prepend-icon="darkThemeSwitch ? 'mdi-weather-night' : 'mdi-white-balance-sunny'"></v-switch> -->
          </div>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { onBeforeUnmount, onMounted, inject, ref, watch } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useDisplay } from 'vuetify'
// import { useRoute, useRouter } from 'vue-router'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Splash',
  metaInfo() {
    return {
    // // Children can override the title.
    // title: `Vue-SPA-Template v${process.env.PACKAGE_VERSION}`,
    // // Result: My Page Title ← My Site
    // // If a child changes the title to "My Other Page Title",
    // // it will become: My Other Page Title ← My Site
    // // titleTemplate: '%s ← My Site',
    // // Define meta tags here.
    // meta: [
    //   {name: 'description', content: 'Description of SPA.'},
    //   // OpenGraph data (Most widely used)
    //   {property: 'og:title', content: 'Description of SPA.'},
    //   {property: 'og:site_name', content: 'Description of SPA.'},
    //   // The list of types is available here: http://ogp.me/#types
    //   {property: 'og:type', content: 'website'},
    //   // Should the the same as your canonical link, see below.
    //   {property: 'og:url', content: 'https://www.prod-spa-site.com/'},
    //   {property: 'og:image', content: 'https://www.prod-spa-site.com/favicon.png'},
    //   // Often the same as your meta description, but not always.
    //   {property: 'og:description', content: 'Description of SPA'},
    //   // Google / Schema.org markup:
    //   {itemprop: 'name', content: `Vue-SPA-Template v${process.env.PACKAGE_VERSION}`},
    //   {itemprop: 'description', content: 'Description of SPA.'},
    //   {itemprop: 'image', content: 'https://www.prod-spa-site.com/favicon.png'}
    // ],
    // link: [
    //   {rel: 'canonical', href: 'https://www.prod-spa-site.com/'}
    // ]
  }
  },
  components: {
  },
  setup() {
    // Logger
    const logger = inject('vuejs3-logger')

    // App Constants
    const componentTitle = ref('Admin Portal')
    const footerCopywriteHolder = ref('ChaosTrack')
    const projectVersion = ref(process.env.PACKAGE_VERSION || '0')

    // Window Size Mgmt
    const { smAndUp, mdAndUp } = useDisplay()
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)

    watch(windowHeight, (newHeight, oldHeight) => {
      logger.debug(`Window height: ${oldHeight} --> ${newHeight}`)
    })
    watch(windowWidth, (newWidth, oldWidth) => {
      logger.debug(`Window width: ${oldWidth} --> ${newWidth}`)
    })

    const windowResizeHandler = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    // Component Lifecycle Hooks

    onMounted(async () => {
      window.addEventListener('resize', windowResizeHandler)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', windowResizeHandler)
    })
    
    // Theme Mgmt
    const currentTheme = ref('dark')
    const darkThemeSwitch = ref(true)

    watch(darkThemeSwitch, (val) => {
      if (val) {
        currentTheme.value = 'dark'
      } else {
        currentTheme.value = 'light'
      }
    })

    // External Links

    // const visitAuth0 = () => {
    //   window.open('https://auth0.com')
    // }

    // Router
    // const router = useRouter()

    // User Authentication
    const { /* getAccessTokenSilently, */ loginWithRedirect, logout, user, isAuthenticated } = useAuth0()
    const logInOrOut = () => {
      if (isAuthenticated.value === true) {
        // Log out
        logout({
          logoutParams: {
            returnTo: window.location.origin
          } 
        })
      } else {
        loginWithRedirect({ appState: { target: '/admin' } })
      }
    }

    // Load User content when auth status changes to true
    watch(isAuthenticated, async (isAuthd) => {
      if (isAuthd) {
        logger.debug(`User is now logged in...`)

      }
    })

    return {
      componentTitle,
      footerCopywriteHolder,
      projectVersion,
      currentTheme,
      darkThemeSwitch,
      smAndUp, // size class bool
      mdAndUp, // size class bool
      windowHeight,
      // Authentication
      isAuthenticated,
      user,
      logInOrOut
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-content {
    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
}
</style>