<template>
    <v-container :class="['bg-transparent']"
        :style="{ 'height': height + 'px', 'max-height': height + 'px', 'width': width ? width + 'px': '100%' }"
        fluid fill-height>
        <v-row wrap dense justify="center" :style="{ 'height': '100%', 'opacity': 1.0 }">
            <v-col cols="12" xs="12" sm="12" :md="12" :lg="width ? 12 : 9" :xl="width ? 12 : 9"
                class="d-flex flex-column justify-center align-center">
                <Splitpanes v-if="isUserAuthorized({ user, testSession }) && !needsScenarioSelect && !needsScenarioSetup" class="splitpanes">
                    <!-- <pane min-size="25">
                        <v-card :height="height" elevation="2" class="ml-1 mr-1 mt-1 mb-1">
                            <v-toolbar color="transparent">
                                <v-toolbar-title class="text-h6">
                                    Executive Summary
                                </v-toolbar-title>
                            </v-toolbar>
                            <div :style="{ 'height': height + 'px' }" class="overflow-y-auto">
                                <v-card-text class="pt-0">{{ testSession?.currentState?.prompt ?? '(Origin State Description)'}}</v-card-text>
                                <v-divider class="ml-2 mr-2"></v-divider>
                                <v-card-subtitle class="mt-2">Major Decisions</v-card-subtitle>
                                <ExecSummaryView v-if="testSession?.executiveSummary" :height="(height - 250)" :testSession="testSession">
                                </ExecSummaryView>
                            </div>
                        </v-card>
                    </pane> -->
                    <pane min-size="70" size="70">
                        <v-card :height="height" elevation="2" class="ml-1 mr-1 mt-1 mb-1">
                            <v-toolbar color="transparent">
                                <v-toolbar-title class="text-h6">
                                    Incident Details
                                </v-toolbar-title>
                                <template v-slot:append>
                                    <v-menu>
                                      <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" size="small">Export</v-btn>
                                      </template>
                                      <v-list>
                                        <v-list-item
                                          :key="0"
                                          :value="0"
                                          :disabled="true"
                                        >
                                          <v-list-item-title>Select an Export Format</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          :key="1"
                                          :value="1"
                                          @click="exportIncidentDetails({ includeDebugDetails: true })"
                                        >
                                          <v-list-item-title>Game Transcript with Debug Info (CSV)</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          :key="2"
                                          :value="2"
                                          @click="exportIncidentDetails({ includeDebugDetails: false })"
                                        >
                                          <v-list-item-title>Game Transcript (CSV)</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                </template>
                            </v-toolbar>
                            <StateEventsView
                                v-if="testSession?.incidentDetails"
                                :isDarkTheme="isDarkTheme"
                                :scrollToBottomOnUpdate="scrollIncidentsToBottomOnUpdate"
                                :height="height"
                                :testSession="testSession"
                                :user="user"
                                :postMessageFunc="postMessageFunc ? addComment : null"
                                :attachmentSourceLoaderFunc="loadAttachmentData"
                                class="ml-1 mr-1 mt-1 mb-1"/>
                        </v-card>
                    </pane>
                    <pane min-size="25">
                        <v-card :height="height" elevation="2" class="ml-1 mr-1 mt-1 mb-1">
                            <v-toolbar color="transparent">
                                <v-toolbar-title class="text-h6">
                                    What would you do?
                                </v-toolbar-title>
                                <template v-slot:append v-if="canRestart">
                                    <v-btn size="small" @click="resetTestSession">Restart</v-btn>
                                </template>
                            </v-toolbar>
                            <v-divider class="ml-2 mr-2"></v-divider>
                            <!-- <div class="d-flex justify-center align-center">
                                <v-divider class="ml-2 mr-1"></v-divider>
                                <v-icon v-if="!isUserCurrentPlayer({ user, testSession })" icon="mdi-account"></v-icon>
                                <v-img v-if="isUserCurrentPlayer({ user, testSession }) && user.picture" contain width="30" :src="user.picture">
                                    <v-tooltip activator="parent" open-delay="500" location="top" origin="end">{{ user.email }}</v-tooltip>
                                </v-img>
                                <v-card-subtitle
                                v-if="testSession?.currentState?.assignedTo"
                                class="ml-1 mr-0 pl-0 pr-0"
                                >{{ testSession?.currentState?.assignedTo }}</v-card-subtitle>
                                <v-divider class="ml-1 mr-2"></v-divider>
                            </div> -->
                            <ActionPicker
                                v-if="testSession?.actionPalette"
                                :isDarkTheme="isDarkTheme"
                                :height="(height - 100)"
                                :actionPalette="testSession.actionPalette"
                                :nlpEnabled="(testSession.currentState.nlpEnabled !== undefined ? testSession.nlpEnabled && testSession.currentState.nlpEnabled : testSession.nlpEnabled)"
                                :testSessionVariables="testSession.variables"
                                :pickActionFunc="actionPicked"
                                :fetchMatchingActionsFunc="getMatchingActionsFunc"
                                :enabled="isUserCurrentPlayer({ user, testSession })"
                            />
                        </v-card>
                    </pane>
                </Splitpanes>
                <ScenarioNotice
                    v-if="!isManager(user) && !isSponsor(user) && isUserAuthorized({ user, testSession }) && (needsScenarioSelect || needsScenarioSetup)"
                    :height="height"
                    :isDarkTheme="isDarkTheme">
                </ScenarioNotice>
                <ScenarioPicker
                    v-if="(isManager(user) || isSponsor(user)) && needsScenarioSelect"
                    :height="height"
                    :pickScenarioFunc="scenarioPicked"
                    :scenarios="scenarios"
                    :isDarkTheme="isDarkTheme">
                </ScenarioPicker>
                <ScenarioSetup
                    v-if="(isManager(user) || isSponsor(user)) && needsScenarioSetup"
                    :height="height"
                    :assignSettingsFunc="scenarioSettingsAssigned"
                    :getUserDataFunc="getUserDataFunc"
                    :inviteUserFunc="inviteUserFunc"
                    :nlpModels="nlpModels"
                    :nlpMetas="nlpMetas"
                    :variables="variables"
                    :testSession="testSession"
                    :isDarkTheme="isDarkTheme">
                </ScenarioSetup>
                <ScenarioNotice
                    v-if="!isUserAuthorized({ user, testSession }) && !isUserInCache({ user, testSession })"
                    :height="height"
                    :prompt="'404'"
                    :message="'Couldn\'t find that scenario.'"
                    :isDarkTheme="isDarkTheme">
                </ScenarioNotice>
                <ScenarioNotice
                    v-if="!isUserAuthorized({ user, testSession }) && isUserInCache({ user, testSession })"
                    :height="height"
                    :prompt="'Congratulations!'"
                    :message="`You've completed your turn in the scenario!`"
                    :isDarkTheme="isDarkTheme">
                </ScenarioNotice>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { inject, watch, ref } from 'vue'
import { useDisplay } from 'vuetify'
import { Splitpanes, Pane } from 'splitpanes'
import StateEventsView from './StateEventsView.vue'
import ActionPicker from './ActionPicker.vue'
import ScenarioPicker from './ScenarioPicker.vue'
import ScenarioSetup from './ScenarioSetup.vue'
import ScenarioNotice from './ScenarioNotice.vue'
// import ExecSummaryView from './ExecSummaryView.vue'
import 'splitpanes/dist/splitpanes.css'
import { userIsRole, userOrg } from '../utils/authUtils.js'
import { renderIncidentsTranscript } from '../utils/exportUtils.js'

export default {
// eslint-disable-next-line vue/multi-word-component-names
name: 'Player',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: null
        },
        user: {
            type: Object,
            required: true
        },
        testSession: {
            type: Object,
            required: false,
            default: () => {}
        },
        nlpModels: {
            type: Array,
            required: false,
            default: () => []
        },
        nlpMetas: {
            type: Array,
            required: false,
            default: () => []
        },
        scenarios: {
            type: Array,
            required: false,
            default: () => []
        },
        variables: {
            type: Array,
            required: false,
            default: () => []
        },
        resetSessionFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('Test session reset requested without function provided!')}
        },
        pickScenarioFunc: {
            type: Function,
            required: false,
            default: async ({ test }) => { console.log(`No handler for selecting scenario: ${JSON.stringify(test)}`) }
        },
        submitActionFunc: {
            type: Function,
            required: false,
            default: async ({ actionId, state }) => { console.log(`Action '${actionId}' submitted for state '${state}' without a function provided!`)}
        },
        getMatchingActionsFunc: {
            type: Function,
            required: false,
            default: async ({ phrase }) => { console.log(`Phrase '${phrase}' submitted for action matching without a function provided!`)}
        },
        submitSessionSettingsFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for assigning settings!') }
        },
        postMessageFunc: {
            type: Function,
            required: false,
            default: null
        },
        getUserDataFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for fetching user data!') }
        },
        inviteUserFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for inviting user!') }
        },
        canRestart: {
            type: Boolean,
            required: false,
            default: false
        },
        attachmentSourceLoaderFunc: {
            type: Function,
            required: false,
            default: async () => { console.log('No handler for fetching attachment data!') }
        }
    },
components: {
    Pane, Splitpanes, StateEventsView, ActionPicker, ScenarioPicker, ScenarioSetup, ScenarioNotice /* , ExecSummaryView */
},
setup(props) {
    // Logger
    const logger = inject('vuejs3-logger')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()
    
    // External Links
    const visitExternalLink = (url) => {
        if (url) {
            window.open(url)
        }
    }

    // Auth Helpers
    
    const isManager = (user) => {
        return userIsRole({ user, role: 'manager' })
    }

    const isSponsor = (user) => {
        return userIsRole({ user, role: 'sponsor' })
    }

    const isUserCurrentPlayer = ({ user, testSession }) => {
        return user && testSession && testSession.currentPlayerEmail === user.email
    }

    const isUserInvited = ({ user, testSession }) => {
        return user && testSession && testSession.allPlayerEmails?.includes(user.email)
    }

    const isUserTestOrgSponsor = ({ user, testSession }) => {
        return isSponsor(user) && (userOrg(user) === testSession?.org)
    }

    const isUserInCache = ({ user, testSession }) => {
        let found = false
        if (user && testSession?.userCache) {
            for (const cachedUser of Object.values(testSession.userCache)) {
                if (cachedUser.email === user.email) {
                    found = true
                    break // true
                }
            }
        }
        return found
    }

    const isUserAuthorized = ({ user, testSession }) => {
        logger.debug(`Checking auth for user '${JSON.stringify(user)}' and session '${JSON.stringify(testSession)}'...`)
        // TODO: option for currentPlayer vs. invitedUser access
        return isManager(user)
            || isUserCurrentPlayer({ user, testSession })
            || isUserInvited({ user, testSession })
            || isUserTestOrgSponsor({ user, testSession })
    }

    watch(props.testSession, async () => {
      logger.debug(`Test Session has changed.`)

      // Show notice to players if test session restarts
      if (!isManager(props.user)) {
        needsScenarioSetup.value = props.testSession.dispatched === false
        needsScenarioSelect.value = props.testSession.dispatched === false
      }
    })

    // UI State
    const needsScenarioSelect = ref(props.testSession.dispatched === false)
    const needsScenarioSetup = ref(false)

    const scrollIncidentsToBottomOnUpdate = ref(false) // don't scroll for refreshes

    const actionPicked = async (action) => {
        logger.debug(`Selected action '${JSON.stringify(action)}' for state '${props.testSession.currentState.name}'`)
        scrollIncidentsToBottomOnUpdate.value = true // scroll for new action
        await props.submitActionFunc({ actionId: action.id, state: props.testSession.currentState.name })
        scrollIncidentsToBottomOnUpdate.value = false // don't scroll for refreshes
    }

    const scenarioPicked = async ({ test }) => {
        logger.debug(`Selected scenario '${JSON.stringify(test)}'.`)
        await props.pickScenarioFunc({ test })
        needsScenarioSelect.value = false
        needsScenarioSetup.value = true
    }

    const scenarioSettingsAssigned = async (vars) => {
        logger.debug(`Assigning settings for scenario.`)
        await props.submitSessionSettingsFunc(vars)
        needsScenarioSetup.value = false
    }

    const resetTestSession = async () => {
        logger.debug(`Resetting test session!`)
        await props.resetSessionFunc()
        needsScenarioSelect.value = true
        needsScenarioSetup.value = true
    }

    const exportIncidentDetails = async ({ includeDebugDetails = true }) => {
        logger.debug(`Exporting incident details...`)
        const renderedTranscript = renderIncidentsTranscript({
            gameDispatchTimestamp: props.testSession?.dispatchTime ?? 0,
            incidents: props.testSession?.incidentDetails,
            variables: props.testSession?.variables,
            roleAssignments: props.testSession?.roleAssignments,
            userCache: props.testSession?.userCache,
            queries: props.testSession?.reportData?.nlpStatistics?.queryDescriptions ?? [],
            includeDebugDetails: includeDebugDetails
        })
        logger.debug(`Incident Details: ${renderedTranscript}`)
        // Generate participant path / CSV Data and download
        // Add header to indicate UTF-8 Data
        const BOM = new Uint8Array([0xef, 0xbb, 0xbf])
        const csvBlob = new Blob([BOM, renderedTranscript])
        const url = window.URL.createObjectURL(csvBlob)
        const link = document.createElement("a")
        link.href = url
        const fileName = `Choastrack_Game_Transcript_${props.testSession.name}.csv`
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const addComment = async ({ message }) => {
        logger.debug(`Adding session comment...`)
        scrollIncidentsToBottomOnUpdate.value = true // scroll for new comment
        await props.postMessageFunc({ message })
        scrollIncidentsToBottomOnUpdate.value = false // don't scroll for refreshes
    }

    const loadAttachmentData = async ({ state, action, name, extension }) => {
        logger.debug(`Loading attachment...`)
        return await props.attachmentSourceLoaderFunc({ test: props.testSession.test, state, action, name, extension })
    }

    return {
    // Functions
    visitExternalLink,
    // Window Size Management
    smAndUp,
    mdAndUp,
    // Player Runtime State
    needsScenarioSelect,
    needsScenarioSetup,
    scrollIncidentsToBottomOnUpdate,
    // Player Runtime Functions
    resetTestSession,
    addComment,
    actionPicked,
    scenarioPicked,
    scenarioSettingsAssigned,
    exportIncidentDetails,
    loadAttachmentData,
    // Auth helpers
    isManager,
    isSponsor,
    isUserCurrentPlayer,
    isUserInCache,
    isUserAuthorized
    }
}
}
</script>

<style lang="scss" scoped>

.splitpanes {background-color: transparent;}

:deep(.splitpanes__splitter) {
    background-color: transparent;
    min-width: 7px;
    // border-left:1px solid #eee;
    cursor:col-resize;
    position: relative;
}
:deep(.splitpanes__splitter:before) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.4s;
  background-color: rgba(255, 0, 0, 0.3);
  opacity: 0;
  z-index: 1;
}
:deep(.splitpanes__splitter:hover:before) {
    opacity: 1;
}

</style>