<template>
    <v-container :class="['bg-transparent', nlpEnabled ? undefined : 'overflow-y-auto']"
        :style="{ 'position': 'relative', 'height': height + 'px', 'min-height': height + 'px', 'max-height': height + 'px', 'width': width ? width + 'px': '100%'}"
        fluid>
        <v-row v-if="nlpEnabled" wrap dense justify="center" :style="{ 'height': '13%', 'opacity': 1.0 }">
            <v-col cols="12">
                <v-combobox v-model="ffSearch" :loading="actionsLoading" :items="[]" clearable :key="formKey"
                    hide-no-data hide-selected hide-details="auto" flat :label="actionEntryHint"
                    :disabled="!enabled"
                    density="compact"
                    :hint="actionSearchHint"
                    append-inner-icon="mdi-magnify" @click:appendInner="manualActionSearch"
                    @keyup.enter="manualActionSearch">
                </v-combobox>
            </v-col>
        </v-row>
        <v-row v-if="nlpEnabled" wrap dense justify="center" class="overflow-y-auto" :style="{ 'width': '100%' }">
            <v-col cols="12">
                <v-list density="compact" nav :lines="false">
                    <!-- <v-list-subheader>List Header</v-list-subheader> -->
                    <v-list-item
                        v-for="(supportedAction, i) in actionMatches"
                        style="min-height: unset;"
                        :key="i"
                        :value="supportedAction"
                        rounded="xl"
                        :disabled="supportedAction.done || !enabled"
                        @click="pickAction(supportedAction)"
                        variant="outlined">
                        <template v-slot:prepend>
                            <div class="d-flex align-center flex-column justify-center pr-2" style="height: 30px;">
                                <v-icon :icon="(supportedAction.done ? 'mdi-check-circle-outline' : 'mdi-radiobox-blank')"></v-icon>
                            </div>
                        </template>
                
                        <!-- <v-list-item-title v-text="supportedAction.action"></v-list-item-title> -->
                        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                        <v-list-item-subtitle v-text="tokenResolvedString(supportedAction.action, testSessionVariables)"></v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row v-if="!nlpEnabled" wrap dense justify="center" class="overflow-y-auto" :style="{ 'width': '100%' }">
            <v-col cols="12">
                <v-list density="compact" nav :lines="false">
                    <!-- <v-list-subheader>List Header</v-list-subheader> -->
                    <v-list-item
                        v-for="(supportedAction, i) in actionPalette"
                        style="min-height: unset;"
                        :key="i"
                        :value="supportedAction"
                        rounded="xl"
                        :disabled="supportedAction.done || !enabled"
                        @click="pickAction(supportedAction)"
                        variant="outlined">
                        <template v-slot:prepend>
                            <div class="d-flex align-center flex-column justify-center pr-2" style="height: 30px;">
                                <v-icon :icon="(supportedAction.done ? 'mdi-check-circle-outline' : 'mdi-radiobox-blank')"></v-icon>
                            </div>
                        </template>
                
                        <!-- <v-list-item-title v-text="supportedAction.action"></v-list-item-title> -->
                        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                        <v-list-item-subtitle v-text="tokenResolvedString(supportedAction.action, testSessionVariables)"></v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-overlay
          id="watcherOverlay"
          :opacity="0"
          v-model="showWatcherOverlay"
          contained
          scroll-strategy="none"
          class="align-center justify-center"
        >
          <v-btn
            disabled
          >
            You are watching this turn.
          </v-btn>
        </v-overlay>
    </v-container>
</template>

<script>
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { tokenResolvedString } from '../utils/uiUtils.js'
export default {
name: 'ActionPicker',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
            type: Number,
            required: false,
            default: null
        },
        actionPalette: {
            type: Array,
            required: true
        },
        nlpEnabled: {
            type: Boolean,
            required: false,
            default: true
        },
        testSessionVariables: {
            type: Object,
            required: false,
            default: () => {}
        },
        fetchMatchingActionsFunc: {
            type: Function,
            required: false,
            default: async () => { console.log(`No phrase/action matching function provided to ActionPicker!`)}
        },
        pickActionFunc: {
            type: Function,
            required: false,
            default: async (action) => { console.log(`No handler for selected action: ${JSON.stringify(action)}`) }
        },
        enabled: { // Whether the input elements are enabled. True for a player (False for a 'watcher')
            type: Boolean,
            required: true
        }
    },
components: {
},
setup(props) {
    // Logger
    const logger = inject('vuejs3-logger')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()
    
    // External Links
    const visitExternalLink = (url) => {
        if (url) {
            window.open(url)
        }
    }

    // Action Picker Event Watchers
    watch(props.actionPalette, () => {
      logger.debug(`Action palette changed!`)

    })

    // Action Picker State
    const showWatcherOverlay = computed(() => {
        if (props.enabled) {
            return false
        }
        return true
    })

    // Free form data
    const actionEntryHint = ref('Search for the best response...')
    const actionsLoading = ref(false)
    const actionMatches = ref([])
    const ffSearch = ref(null)
    const formKey = ref('combokey')
    const actionSearchHint = ref('')

    // Free Form Search
    const manualActionSearch = async () => {
        actionsLoading.value = true
        actionSearchHint.value = ''
        if (ffSearch.value) {
            const fetchResult = await props.fetchMatchingActionsFunc({ phrase: ffSearch.value })
            actionMatches.value = fetchResult?.result?.filter(action => action.done !== true) ?? []
            if (fetchResult.message) {
                actionSearchHint.value = fetchResult.message
            } else if (actionMatches.value.length === 0) {
                actionSearchHint.value = 'No matches. Try something else.'
            }
        }
        actionsLoading.value = false
    }

    watch(ffSearch, async (val) => {
        if (!val) {
            actionMatches.value = []
            actionSearchHint.value = ''
        }
    })

    const pickAction = async (action) => {
        ffSearch.value = null
        formKey.value = 'newVal'
        await props.pickActionFunc(action)
        // Force redraw and reset of the v-combobox
        formKey.value = 'combokey'
    }

    onMounted(() => {
        logger.debug(`Action Picker mounted!`)
    })

    return {
        // Functions
        visitExternalLink,
        // Window Size Management
        smAndUp,
        mdAndUp,
        // Action Picker State
        showWatcherOverlay,
        // Action Picker Functions
        pickAction,
        manualActionSearch,
        // Free Form Action Picker
        actionEntryHint,
        actionSearchHint,
        actionsLoading,
        actionMatches,
        formKey,
        ffSearch,
        // Utils
        tokenResolvedString
    }
}
}
</script>

<style lang="scss" scoped>

:deep(.v-overlay__scrim) {
  opacity: 35%;
}

:deep(.v-field__input > input) {
  width: 100%;
}

</style>